import React, { useCallback } from 'react';
import { Avatar, Button, FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import IconArrowLeft from '../../../icons/IconArrowLeft';
import IconSearch from '../../../icons/IconSearch';
import PerfectScrollbar from 'react-perfect-scrollbar';
import debounce from '../../debounce/debounce';
import { UserDetails } from '../../../../models/authModels';
import getInitials from '../../../getInitials/getInitials';

const AddParticipantComponent = ({
  userDetails,
  setOpenModal,
  userSuggestions,
  getUserList,
  addToGroup,
  participantsObj,
}: AddParticipantComponentProps) => {
  const handleSearch = useCallback(debounce(getUserList, 400), []);

  return (
    <div className="add-delete-chat-modal">
      <div className="back-wrap ml ">
        <IconButton size="large" component="span" onClick={() => setOpenModal(0)}>
          <IconArrowLeft />
        </IconButton>
        <h4 className="back-text">Add User</h4>
      </div>
      <div className="chat-list-wrap">
        <div className="form-wrap">
          <form noValidate autoComplete="off">
            <div className="from-row theam-black">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <FormControl fullWidth variant="outlined" className="formControl pl">
                  <OutlinedInput
                    type="text"
                    onChange={handleSearch}
                    color="info"
                    placeholder="Search any user by Name or Email ID"
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton>
                          <IconSearch />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
          </form>
          {userSuggestions.length > 0 && (
            <div className="addlist-h">
              <PerfectScrollbar>
                {userSuggestions.map(
                  userSuggestion =>
                    userDetails.userId !== userSuggestion.userid &&
                    !participantsObj[userSuggestion.userid] && (
                      <div className="chat-list" key={userSuggestion.userid}>
                        <div className="media">
                          <span className="avater-icon">
                            <Avatar className="d-flex align-self-center" src={userSuggestion.profileimage}>
                              {getInitials(userSuggestion.username)}
                            </Avatar>
                          </span>
                          <div className="media-body">
                            <div className="d-flex align-items-center">
                              <h6 className="mb-0 flex-grow-1">{userSuggestion.username}</h6>
                              <span>
                                <Button
                                  variant="text"
                                  color="error"
                                  className="chat-action-button"
                                  onClick={() => addToGroup(userSuggestion.userid.toString())}
                                >
                                  Add
                                </Button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                )}
              </PerfectScrollbar>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface AddParticipantComponentProps {
  userDetails: UserDetails;
  setOpenModal: (openModal: number) => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userSuggestions: any[];
  getUserList: (e: React.SyntheticEvent) => void;
  addToGroup: (identity: string) => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantsObj?: any;
}

export default AddParticipantComponent;
