import { useQuery } from '@tanstack/react-query';
import { getActiveCapCall } from 'src/services/governanceService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';

export const useActiveCapCall = (fundID: number) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchActiveCapCall = async () => {
    try {
      const response = await getActiveCapCall(fundID);
      return response.data;
    } catch (error) {
      showErrorSnackbar((error as AxiosError).message);
      console.error('Fetch Active CapCall error', error);
    }
  };

  return useQuery({
    queryKey: [QueryKey.ActiveCapCall, fundID],
    queryFn: () => fetchActiveCapCall(),
  });
};
