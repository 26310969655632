import { useState } from 'react';
import IconCollapsed from 'src/uiLibrary/icons/IconCollapsed';
import { Privilege as PrivilegeType } from 'src/types/Roles';

interface PrivilegeProps {
  privilege: PrivilegeType;
  onParentChange: (parentId: number) => void;
  onChildChange: (parentId: number, childId: number) => void;
}

export const Privilege = ({ onParentChange, onChildChange, privilege }: PrivilegeProps) => {
  const [open, setOpen] = useState(true);
  const childPrivileges = privilege?.childPrivilege;

  return (
    <>
      <div>
        <div className="row-header">
          <div className="dropdown-row">
            <div className="parent-row">
              <div className="label-group" onClick={() => setOpen(state => !state)}>
                <div className="title-privilege">{privilege?.Name}</div>
                {privilege?.childPrivilege?.length > 0 && (
                  <div style={{ transform: !open ? 'rotate(180deg)' : '' }}>
                    <IconCollapsed />
                  </div>
                )}
              </div>
            </div>
            <div className="separator"></div>
            <div>
              <input
                id={privilege.id.toString()}
                type="checkbox"
                checked={privilege.checked}
                onChange={() => onParentChange(privilege.id)}
              />
            </div>
          </div>
        </div>
        {open && childPrivileges?.length > 0 && (
          <div className="children-wrapper">
            {childPrivileges.map(childPriv => {
              return (
                <div key={childPriv.id} className="dropdown-row">
                  <div className="label-group">
                    <div className="sub-title-privilege">{childPriv?.Name}</div>
                  </div>
                  <div className="separator"></div>
                  <div>
                    <input
                      checked={childPriv.checked}
                      id={childPriv.id.toString()}
                      type="checkbox"
                      onChange={() => {
                        onChildChange(privilege.id, childPriv.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
