import { createTheme } from '@mui/material/styles';
import { ButtonComponentsTheme, dialogComponentsTheme } from './components';

export const extranceThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#024A72',
    },
    secondary: {
      main: '#cf0',
    },
    info: {
      main: '#fff',
    },
    success: {
      main: '#00E497',
    },
    warning: {
      main: '#FFBF4C',
    },
    error: {
      main: '#FF5953',
    },
  },
  typography: {
    button: {
      fontFamily: 'Poppins',
    },
    fontFamily: 'Poppins',
  },

  components: {
    ...dialogComponentsTheme,
    ...ButtonComponentsTheme,
  },
});
