import { Dialog, DialogContent } from '@mui/material';
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { useState } from 'react';
import GeneralDistributionForm from './GeneralDistributionForm';
import Progressbar from '../../reusables/progress-bar/progress-bar';
import ReviewPaymentStructure from './ReviewPaymentStructureForm';
import FinalReviewDisplay from './FinalReviewDisplay';
import { previewWaterfall, triggerCapDistribution } from '../../../services/governanceService';
import { parseToNumberFromCurrency } from '../../../views/utils';
import { IconCheckSuccess } from '../../icons/IconCheckSuccess';
import { DeployCapitalCallForm } from '../deployCapitalCall/deployCapitalCallComponent';
import { Fund } from 'src/types/Funds';

const DistributeCapitalModalComponents = ({
  isVisible,
  closeModal,
  // onSubmit,
  isFboFundingOpen,
  setIsFboFundingOpen,
  fund,
  fboBalance,
}: DistributeCapitalModalComponentsProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    error: false,
    errorType: '',
    errorMessage: '',
  });
  const [distributionFormData, setDistributionFormData] = useState<DistributeCapitalGeneralForm>({
    FundName: '',
    FundCode: '',
    CapitalToDistribute: '0',
  });
  const [selectedCard, setSelectedCard] = useState<'' | 'fbo' | 'ach'>('');
  const [manualPortion, setManualPortion] = useState(0);
  const [automatedPortion, setAutomatedPortion] = useState(0);
  const [totalGpAmount, setTotalGpAmount] = useState(0);
  const [totalLpAmount, setTotalLpAmount] = useState(0);

  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const discriminateManualOrAutomated = (data: any) => {
    let manualStack = 0;
    let automatedStack = 0;
    let gpAmount = 0;
    let lpAmount = 0;
    if (data.gpCapitalDistribution.transferType === 'manual') {
      manualStack += data.gpCapitalDistribution.capital;
    } else {
      automatedStack += data.gpCapitalDistribution.capital;
    }
    gpAmount += data.gpCapitalDistribution.capital;
    // TODO: get type from API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data.lpCapitalDistribution.forEach((user: any) => {
      if (user.transferType === 'manual') {
        manualStack += user.capital;
      } else {
        automatedStack += user.capital;
      }
      lpAmount += user.capital;
    });
    setManualPortion(manualStack);
    setAutomatedPortion(automatedStack);
    setTotalGpAmount(gpAmount);
    setTotalLpAmount(lpAmount);
  };

  const fetchPreviewWaterfall = async (id: number, amount: number) => {
    setLoader(true);
    const res = await previewWaterfall(id, amount);
    if (res.status === 200) {
      if (res.data.status === 200) {
        discriminateManualOrAutomated(res.data.data);
      } else if (res.data.status === 404) {
        setError({
          error: true,
          errorType: 'Missing bank account',
          errorMessage: res.data.message,
        });
      } else {
        setError({
          error: true,
          errorType: 'Something went wrong',
          errorMessage: 'try again later',
        });
      }
      setLoader(false);
    } else {
      setError({
        error: true,
        errorType: 'Something went wrong',
        errorMessage: 'try again later',
      });
      setLoader(false);
    }
  };
  const onSubmitLocal = (formData: DistributeCapitalGeneralForm) => {
    // we set the requested amount without format
    // const requestedAmountFromUser = getValues("RequestedAmount");
    // const requestedAmountWithoutFormat = parseFloat(requestedAmountFromUser.toString().replace(/,/g, ''));
    // formData["RequestedAmount"] = `${requestedAmountWithoutFormat}`;

    // sacar coma y devolver en string el numero del CapitalToDistribute
    // let res = [formData]
    // onSubmit(formData);
    if (activeStep === 0) {
      setDistributionFormData(formData);
      fetchPreviewWaterfall(fund!.id, parseToNumberFromCurrency(formData.CapitalToDistribute));
    }
    setActiveStep(activeStep + 1);
  };

  const handleTriggerCapDistribution = async () => {
    setLoader(true);
    const res = await triggerCapDistribution(
      fund!.id,
      parseToNumberFromCurrency(distributionFormData.CapitalToDistribute),
      selectedCard === 'ach' ? 1 : 0,
    );
    if (res.status === 200) {
      setSuccess(true);
      setLoader(false);
    }
  };
  const handleOnClose = () => {
    // reset states
    setActiveStep(0);
    setDistributionFormData({
      FundName: '',
      FundCode: '',
      CapitalToDistribute: '0',
    });
    setSelectedCard('');
    setError({ error: false, errorMessage: '', errorType: '' });
    setLoader(false);
    setSuccess(false);
    closeModal();
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleOnClose}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="dialog-no-padding"
      maxWidth={false}
    >
      <DialogContent className="generic-form-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={handleOnClose}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>

          <Progressbar
            progressValue={activeStep}
            customNodeCount={['Distribution', 'Review payments', 'Final Review']}
          />

          <div className="dialog-form-wrap">
            {activeStep === 0 ? (
              <GeneralDistributionForm onSubmit={onSubmitLocal} fundName={fund?.name} fundCode={fund?.fundCode} />
            ) : activeStep === 1 ? (
              <ReviewPaymentStructure
                onSubmit={onSubmitLocal}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                isFboFundingOpen={isFboFundingOpen}
                setIsFboFundingOpen={setIsFboFundingOpen}
                fboBalance={fboBalance}
                manualPortion={manualPortion}
                automatedPortion={automatedPortion}
              />
            ) : activeStep === 2 ? (
              <FinalReviewDisplay
                fboBalance={fboBalance}
                onSubmit={handleTriggerCapDistribution}
                distributionAmount={parseInt(distributionFormData.CapitalToDistribute.replace(',', ''))}
                selectedCard={selectedCard}
                manualPortion={manualPortion}
                automatedPortion={automatedPortion}
                gpAmount={totalGpAmount}
                lpAmount={totalLpAmount}
                fundName={fund?.name || ''}
                fundId={fund?.id || 0}
              />
            ) : null}
            {loader && <div className="loader-screen">LOADING...</div>}
            {error.error && (
              <div className="error-screen">
                Error
                <div className="error-type">{error.errorType}</div>
                <div className="error-message">{error.errorMessage}</div>
              </div>
            )}
            {success && (
              <div className="success-screen">
                <IconCheckSuccess />
                Distribution created successfully
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface DistributeCapitalModalComponentsProps {
  isVisible: boolean;
  closeModal: () => void;
  onSubmit: (formData: DeployCapitalCallForm) => void;
  isFboFundingOpen: boolean;
  setIsFboFundingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fund: Fund | undefined | null;
  fboBalance: number | undefined | null;
}

export default DistributeCapitalModalComponents;

export interface DistributeCapitalGeneralForm {
  FundCode: string;
  FundName: string;
  CapitalToDistribute: string;
}

export interface DistributeCapitalFromInvestmentsForm {
  InvestmentList: {
    investmentCode: string;
    investmentName: string;
    CapitalToDistribute: number;
  }[];
}
