import React from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DashboardPhoto from 'src/assets/images/dashboard.png';
import { LoginPayload } from 'src/models/authModels';
import { SignInForm } from './components/SignInForm';
import { CodeVerificationForm } from './components/CodeVerificationForm';
import { useVerifyOtp } from 'src/hooks/react-query/auth/useVerifyOtp';
import { useResendOtp } from 'src/hooks/react-query/auth/useResendOtp';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { LocalStorageService } from 'src/services/LocalStorage';
import { Routes } from 'src/consts/routes';

const LoginComponent = ({ onSubmit }: LoginComponentProps) => {
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem('extrance_user') || '{}');

  const { mutateAsync: verifyOtp, isError } = useVerifyOtp();
  const { mutate: resendOtpCode } = useResendOtp();

  const handleSubmitVerification = (otpCode: string) => {
    try {
      navigator.geolocation.getCurrentPosition(
        location => {
          verifyOtp({
            otp: otpCode,
            email: userDetails.Email,
            location: `${location.coords.latitude},${location.coords.longitude}`,
          }).then(response => {
            response.accessToken && LocalStorageService.setItem('extrance_access_token', response.accessToken);
            response.refreshToken && LocalStorageService.setItem('extrance_refresh_token', response.refreshToken);
            response.expiresIn && LocalStorageService.setItem('extrance_expires_in', response.expiresIn);

            response.accessToken && navigate(Routes.dashboard);
          });
        },
        () => {
          verifyOtp({
            otp: otpCode,
            email: userDetails.Email,
            location: null,
          }).then(response => {
            response.accessToken && LocalStorageService.setItem('extrance_access_token', response.accessToken);
            response.refreshToken && LocalStorageService.setItem('extrance_refresh_token', response.refreshToken);
            response.expiresIn && LocalStorageService.setItem('extrance_expires_in', response.expiresIn);

            response.accessToken && navigate(Routes.dashboard);
          });
        },
        {
          timeout: 2000,
        },
      );
    } catch {
      triggerLogout();
    }
  };

  const handleResendOtpCode = () => {
    resendOtpCode(userDetails.Email);
  };

  const triggerLogout = () => {
    LocalStorageService.removeItem('extrance_refresh_token');
    LocalStorageService.removeItem('extrance_expires_in');
    LocalStorageService.removeItem('extrance_user');
  };

  const isAuthShown = !Object.values(userDetails).length;
  const isOTP = !localStorage.getItem('extrance_access_token')?.length && !!Object.values(userDetails).length;

  return (
    <div className="login-wrapper">
      <PerfectScrollbar>
        <div className="inner-wrap">
          <div className="split-view-wrapper">
            <div className="col-sm-5 col-md-5 col-lg-5 h-100 left-side">
              <div className="fromfield-wrap">
                {isAuthShown && <SignInForm onSubmit={onSubmit} />}
                {isOTP && (
                  <CodeVerificationForm
                    isError={isError}
                    onSubmit={handleSubmitVerification}
                    onBack={triggerLogout}
                    onResend={handleResendOtpCode}
                  />
                )}
                <div className="copywright">
                  <p>
                    By completing this login you agree to the{' '}
                    <span style={{ cursor: 'pointer' }} className="terms-conditions">
                      Terms & Conditions
                    </span>{' '}
                    <span style={{ cursor: 'pointer' }}>of the service</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="title-text">
                <h2>Welcome to the future of investment management.</h2>
                <p className="subtitle">
                  Extrance makes your workflow easier, more transparent and allows you to make decisions faster.
                </p>
              </div>
              <img className="dashboard-photo" alt="dashboard" src={DashboardPhoto}></img>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default LoginComponent;
interface LoginComponentProps {
  onSubmit: (formData: LoginPayload) => void;
}
