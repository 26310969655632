import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getFunds } from 'src/services/fundsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';

export const useFunds = () => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchFund = async () => {
    try {
      const response = await getFunds();
      return response.data?.funds;
    } catch (e) {
      console.log('useFunds error', e);
      showErrorSnackbar((e as AxiosError).message);
      throw new Error((e as AxiosError).message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.MyFunds],
    queryFn: () => fetchFund(),
  });
};
