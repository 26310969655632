import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { getPrivilegeListForRole } from 'src/services/rolesService';

export const usePrivileges = () => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['privilagesList'],
    queryFn: async () => {
      try {
        const responce = await getPrivilegeListForRole();
        return responce.data;
      } catch (e) {
        console.log('usePrivileges error', e);
        showErrorSnackbar((e as AxiosError).message);
        throw new Error((e as AxiosError).message);
      }
    },
  });
};
