/* eslint-disable @typescript-eslint/no-unused-vars */
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useModal } from 'src/hooks/useModal';
import { createCapCall } from 'src/services/fundsService';
import { DeployCapitalCallForm } from 'src/uiLibrary/modals/deployCapitalCall/deployCapitalCallComponent';
import { getFboAvailable } from 'src/services/governanceService';
import { useQuery } from '@tanstack/react-query';
import NdaAproveContainer from 'src/uiLibrary/modals/nda-approve/ndaApproveContainer';
import TransactionAproveContainer from 'src/uiLibrary/modals/transaction-approve/TransactionApproveContainer';
import MarketplaceActContainer from 'src/uiLibrary/modals/marketplace-activity/marketplaceActContainer';
import DeployCapitalCallModalContainer from 'src/uiLibrary/modals/deployCapitalCall/deployCapitalCallContainer';
import DistributeCapitalModalContainer from 'src/uiLibrary/modals/distributeCapital/DistributeCapitalContainer';
import { useState } from 'react';
import { getAgreementRequest } from 'src/services/marketplaceService';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getFundAgreementDocuments } from 'src/services/documentsService';
import { QueryKey } from 'src/consts/query';

enum GovernanceDialog {
  CapCall,
  DistributeCapital,
  MPA,
  Transaction,
  Nda,
}

interface GovernanceActionsProps {
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fund: any;
}

// TODO: refactore component
export const GovernanceActions = ({ fund }: GovernanceActionsProps) => {
  const { isOpen, handleOpenModal, handleCloseModal } = useModal<GovernanceDialog>();
  const [isFboFundingOpen, setIsFboFundingOpen] = useState(false);

  const {
    data: fboBalance,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.FboBalance, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getFboAvailable(fund.id);
        return response.data[0]?.Value;
      }
      return 0;
    },
  });

  const {
    data: counterSign,
    // isLoading,
    // isError,
  } = useQuery({
    queryKey: [QueryKey.CounterSignStatus, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getAgreementRequest(fund.id);
        return response.data;
      }
      return null;
    },
  });

  const {
    data: agreementDocs,
    // isLoading,
    // isError,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.AgreementDocs, fund.id],
    queryFn: async () => {
      if (fund.id) {
        const response = await getFundAgreementDocuments(fund.id);
        return response.data;
      }
      return null;
    },
  });

  const openDeployCapCallModal = () => {
    handleOpenModal(GovernanceDialog.CapCall);
  };
  const openDistributeCapitalModal = () => {
    handleOpenModal(GovernanceDialog.DistributeCapital);
  };
  const openMPAModal = () => {
    handleOpenModal(GovernanceDialog.MPA);
  };
  const openTransactionModal = () => {
    handleOpenModal(GovernanceDialog.Transaction);
  };
  const openNdaModal = () => {
    handleOpenModal(GovernanceDialog.Nda);
  };

  const handleDeployCapitalCallOnSubmit = (formData: DeployCapitalCallForm) => {
    createCapCall(formData);
    // handle On Submit
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDistributeCapitalOnSubmit = (formData: DeployCapitalCallForm) => {
    // handle On Submit
  };

  return (
    <>
      <List className="actions-box">
        <h3>Governance actions</h3>
        <ListItem button className="list-item" onClick={openDeployCapCallModal}>
          <ListItemText className="item-text">Deploy capital call</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openDistributeCapitalModal}>
          <ListItemText className="item-text">Distribute capital</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openMPAModal}>
          <ListItemText className="item-text">Marketplace activity</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openTransactionModal}>
          <ListItemText className="item-text">Approve transactions</ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
        <ListItem button className="list-item" onClick={openNdaModal}>
          <ListItemText className="item-text"> Approve NDA’s </ListItemText>
          <ListItemIcon className="icon">
            <ArrowForwardIcon className="arrow-icon" />
          </ListItemIcon>
        </ListItem>
      </List>
      {
        //TODO: refactore each modal
      }
      {/* <Dialog
        open={isOpen(GovernanceDialog.CapCall)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.DistributeCapital)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.MPA)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.Nda)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog>
      <Dialog
        open={isOpen(GovernanceDialog.Transaction)}
        onClose={handleCloseModal}
        sx={{
          backdropFilter: 'blur(20px)',
        }}
        className="approvenda-dialog"
        PaperProps={{ sx: { maxWidth: '100%' } }}
      >
      </Dialog> */}
      <NdaAproveContainer
        closeModal={handleCloseModal}
        loadNdaData={refetch}
        isVisible={isOpen(GovernanceDialog.Nda)}
        agreementDocs={agreementDocs}
        fund={fund}
      />
      <TransactionAproveContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.Transaction)}
        fund={fund}
      />
      <MarketplaceActContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.MPA)}
        fund={fund}
        countersignTableData={counterSign}
      />
      <DeployCapitalCallModalContainer
        isVisible={isOpen(GovernanceDialog.CapCall)}
        closeModal={handleCloseModal}
        handleOnSubmit={handleDeployCapitalCallOnSubmit}
        fund={fund}
      />
      <DistributeCapitalModalContainer
        isVisible={isOpen(GovernanceDialog.DistributeCapital)}
        closeModal={handleCloseModal}
        handleOnSubmit={handleDistributeCapitalOnSubmit}
        isFboFundingOpen={isFboFundingOpen}
        setIsFboFundingOpen={setIsFboFundingOpen}
        fund={fund}
        fboBalance={fboBalance}
      />
    </>
  );
};
