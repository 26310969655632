import { useNavigate } from 'react-router-dom';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { FundsPerformanceInformation } from 'src/models/dashboardModel';

type FundIndicatorProps = {
  fund: FundsPerformanceInformation;
};

const FundIndicator = ({ fund }: FundIndicatorProps) => {
  const navigate = useNavigate();

  const { id, title, total_realized_pl: totalRealisedPl, multiple, chart_data: chartData } = fund;

  const onClick = () => {
    navigate(`/my-funds/detail/${id}`);
  };

  return (
    <div className="funds-metrics" onClick={onClick}>
      <div className="information-container">
        <div className="information-title">{title}</div>
        <div className="information-body">
          <div className="information-body-item">
            <h4>Total realized P&amp;L</h4>
            <h2>{totalRealisedPl}</h2>
          </div>
          <div className="information-body-item">
            <h4>Multiple (Total)</h4>
            <h2>{multiple}</h2>
          </div>
        </div>
      </div>
      <div className="chart-container">
        <ResponsiveContainer>
          <PieChart style={{ cursor: 'pointer' }} margin={{ top: 30, right: 0, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="1" y1="0" x2="0.1" y2="0">
                <stop offset="40%" stopColor="#61C2A0" stopOpacity={0.8} />
                <stop offset="60%" stopColor="#FFB156" stopOpacity={0.8} />
                <stop offset="80%" stopColor="#FF6565" stopOpacity={0.8} />
              </linearGradient>
            </defs>
            <Pie
              data={chartData}
              blendStroke
              startAngle={0}
              endAngle={180}
              innerRadius={40}
              outerRadius={46}
              dataKey="value"
              fill="url(#colorUv)"
              isAnimationActive={false}
            >
              <Label value={`${chartData[1].value.toFixed(2)} %`} position="centerBottom" className="label-top" />
              <Label value="Gross IRR" position="centerTop" className="label" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default FundIndicator;
