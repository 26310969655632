import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useUserRole } from 'src/hooks/usePermission';
import { useRedirectBasedOnPermissions } from 'src/hooks/useRedirectBasedOnPermissions';

interface ProtectedPageProps {
  children: JSX.Element;
  permissions: string[];
  allowForLp?: boolean;
}

export const ProtectedPage = ({ children, permissions, allowForLp = false }: ProtectedPageProps) => {
  const { userData } = useAuth();
  const { isMainGp, isLp } = useUserRole();
  const { getRedirectRoute } = useRedirectBasedOnPermissions();

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  if (isMainGp || (isLp && allowForLp)) {
    return children;
  }

  const hasPermissions = permissions.every(permission => userData.permissions?.includes(permission));

  if (hasPermissions) {
    return children;
  }

  const redirectRoute = getRedirectRoute(allowForLp);

  return <Navigate to={redirectRoute} replace />;
};
