import WaterfallContainer from '../../../../components/waterfall/WaterfallContainer';
import { ReactComponent as ChevronDown } from '../../../../assets/images/icons/chevron-down.svg';

const WaterfallAccordion = ({ distributionAmount, unrolled, setUnrolled }: WaterfallAccordionProps) => {
  const handleChevronClick = () => {
    if (unrolled) {
      setUnrolled('');
    } else {
      setUnrolled('waterfall');
    }
  };
  return (
    <div className={`review-accordion-container ${unrolled && 'unrolled-accordion'}`}>
      <div className="accordion-header">
        <div className="title-and-subtitle-wrap">
          <p className="title">Waterfall progress</p>
          <p className="subtitle">After the distribution is performed, the waterfall will look the following:</p>
        </div>
        <div className="chevron-box" onClick={() => handleChevronClick()}>
          <ChevronDown />
        </div>
      </div>
      <div className="accordion-content">
        <div style={{ width: 800 }}>
          <WaterfallContainer distributionAmount={distributionAmount} distributeStack={true} />
        </div>
      </div>
    </div>
  );
};

interface WaterfallAccordionProps {
  distributionAmount: number;
  unrolled: boolean;
  setUnrolled: React.Dispatch<React.SetStateAction<string>>;
}

export default WaterfallAccordion;
