import React from 'react';
import { Client, Conversation, JSONObject, Message, Paginator, Participant } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserDetails } from '../../../models/authModels';
import { CustomConversation, PrivateChatDetails, TypingPeople } from '../../../models/chatModels';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { RootState } from '../../../redux/store';
import { getGroupMemberList } from '../../../services/chatService';
import AddParticipantContainer from './AddParticipant/addParticipantContainer';
import ChatBoardComponent from './chatBoardComponent';
import DeleteParticipantContainer from './DeleteParticipant/deleteParticipantContainer';
import MentionsContainer from './Mentions/mentionsContainer';

const ChatBoardContainer = ({
  ShowSnackbarAction,
  userDetails,
  setScreenVal,
  client,
  conversation,
  privateChatDetails,
  setCustomConvo,
}: ChatBoardContainerProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [openModal, setOpenModal] = useState(0);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [messageObj, setMessageObj] = useState<Paginator<Message>>();
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [participantList, setParticipantList] = useState<any[]>([]);
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [participantsObj, setParticipantsObj] = useState<any>();
  const [readStatus, setReadStatus] = useState<number>(0);
  const [typingPeople, setTypingPeople] = useState<TypingPeople[]>([]);
  const [msgMentions, setMsgMentions] = useState<string[]>([]);
  const [currentParticipant, setCurrentParticipant] = useState<Participant>();

  const messageAddHandler = async (message: Message) => {
    setMessages(prevState => {
      return prevState ? [...prevState, message] : [message];
    });

    await conversation?.setAllMessagesRead();
  };

  const handleTypingStarted = (participant: Participant) => {
    if (!!participant?.identity && !!participantsObj) {
      const typingPerson = participantsObj[participant?.identity];
      if (typingPerson && typingPerson.userName) {
        setTypingPeople(typingPeople => [
          ...typingPeople,
          {
            identity: participant.identity!,
            name: typingPerson.userName,
          },
        ]);
      }
    }
  };

  const handleTypingStopped = (participant: Participant) => {
    setTypingPeople(typingPeople => typingPeople.filter(person => person.identity !== participant.identity));
  };

  const getCurrentParticipant = async () => {
    const participant = await conversation?.getParticipantByIdentity(userDetails.userId.toString());
    if (participant) setCurrentParticipant(participant);
  };

  useEffect(() => {
    if (client?.connectionState === 'connected' && !!conversation) {
      getCurrentParticipant();
      getMessageHistory();
      if (participantList.length <= 1) {
        getParticipantList();
      }
      updateReadStatus();
      conversation.setAllMessagesRead();
      conversation.on('messageAdded', messageAddHandler);
      conversation.on('participantUpdated', updateReadStatus);
      conversation.on('typingStarted', handleTypingStarted);
      conversation.on('typingEnded', handleTypingStopped);

      return () => {
        conversation.off('messageAdded', messageAddHandler);
        conversation.off('participantUpdated', updateReadStatus);
        conversation.off('typingStarted', handleTypingStarted);
        conversation.off('typingEnded', handleTypingStopped);
      };
    }
  }, [client, conversation, participantsObj, currentParticipant]);

  const fetchMoreData = () => {
    if (hasPrevPage) {
      messageObj
        ?.prevPage()
        .then(response => {
          if (currentParticipant) {
            const newMsgArray = response.items.filter(
              msg =>
                msg.dateCreated && currentParticipant?.dateCreated && currentParticipant.dateCreated < msg.dateCreated,
            );
            setMessageObj(response);
            setMessages(prevState => [...newMsgArray, ...prevState!]);
            setHasPrevPage(response.hasPrevPage);
          }
        })
        .catch(() =>
          ShowSnackbarAction({
            message: "Couldn't Fetch Messages",
            severity: 'error',
          }),
        );
    }
  };

  const getMessageHistory = () => {
    conversation
      ?.getMessages()
      .then(response => {
        if (currentParticipant) {
          const newMsgArray = response.items.filter(
            msg =>
              msg.dateCreated && currentParticipant?.dateCreated && currentParticipant.dateCreated < msg.dateCreated,
          );
          setMessageObj(response);
          setMessages(newMsgArray);
          setHasPrevPage(response.hasPrevPage);
        }
      })
      .catch(() =>
        ShowSnackbarAction({
          message: "Couldn't Fetch Messages",
          severity: 'error',
        }),
      );
  };

  const sendMessage = () => {
    const result = newMessage.trim();
    if (result) {
      conversation
        ?.sendMessage(result, {
          author: userDetails.Email,
          msgMentions,
        })
        .then(() => {
          setNewMessage('');
          setMsgMentions([]);
        })
        .catch(() => {
          ShowSnackbarAction({
            message: 'Message could not be sent',
            severity: 'error',
          });
        });
    }
  };

  const getParticipantList = () => {
    const participants: JSONObject = {};
    if (conversation?.sid) {
      getGroupMemberList(conversation.sid)
        .then(response => {
          response.data.data.forEach(
            // TODO: get type from API
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => (participants[item.identity!] = item),
          );
          setParticipantList(response.data.data);
          setParticipantsObj(participants);
        })
        .catch(() => {
          ShowSnackbarAction({
            message: 'Error Getting Member List',
            severity: 'error',
          });
        });
    }
  };

  const updateReadStatus = async () => {
    let minReadCount = (await conversation?.getMessagesCount()) ?? 0;
    const participants = await conversation?.getParticipants();
    participants?.forEach(item => {
      minReadCount =
        item.lastReadMessageIndex === null || item.lastReadMessageIndex < minReadCount
          ? item.lastReadMessageIndex ?? -1
          : minReadCount;
    });
    setReadStatus(minReadCount);
  };

  return (
    <>
      <ChatBoardComponent
        setScreenVal={setScreenVal}
        messages={messages}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        sendMessage={sendMessage}
        userDetails={userDetails}
        setOpenModal={setOpenModal}
        hasPrevPage={hasPrevPage}
        fetchMoreData={fetchMoreData}
        participantsObj={participantsObj}
        conversation={conversation}
        privateChatDetails={privateChatDetails}
        setCustomConvo={setCustomConvo}
        readStatus={readStatus}
        typingPeople={typingPeople}
      />
      <MentionsContainer
        participantList={participantList}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        setMsgMentions={setMsgMentions}
      />
      {openModal === 1 && (
        <AddParticipantContainer
          setOpenModal={setOpenModal}
          conversation={conversation}
          participantsObj={participantsObj}
          getParticipantList={getParticipantList}
        />
      )}
      {openModal === 2 && (
        <DeleteParticipantContainer
          setOpenModal={setOpenModal}
          conversation={conversation}
          participantList={participantList}
          getParticipantList={getParticipantList}
        />
      )}
    </>
  );
};

interface ChatBoardContainerProps {
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  userDetails: UserDetails;
  setScreenVal: (screenNum: number) => void;
  client?: Client;
  conversation?: Conversation;
  privateChatDetails?: PrivateChatDetails;
  setCustomConvo: (customConvo?: CustomConversation) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {
  ShowSnackbarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoardContainer);
