import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import { Button, InputAdornment, InputProps, TextField } from '@mui/material';
import { DistributeCapitalGeneralForm } from './DistributeCapitalComponent';
import { NumericFormat } from 'react-number-format';

type GeneralDistributionFormProps = {
  onSubmit: (formData: DistributeCapitalGeneralForm) => void;
  fundName: string | undefined;
  fundCode: string | undefined;
};

const GeneralDistributionForm = ({ onSubmit, fundName, fundCode }: GeneralDistributionFormProps) => {
  const CustomFormField = ({
    fieldKey,
    fieldName,
    placeholder,
    size,
    inputProps,
    disabled,
  }: {
    fieldKey: keyof DistributeCapitalGeneralForm;
    fieldName: string;
    placeholder?: string;
    size: number;
    inputProps?: InputProps;
    disabled?: boolean;
  }) => (
    <div className={`col-sm-${size}`}>
      <Controller
        name={fieldKey}
        control={control}
        rules={{ required: 'Required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>{fieldName}</FormLabel>
            <TextField
              fullWidth
              type="text"
              placeholder={placeholder || ''}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={inputProps}
              disabled={disabled}
            />
          </FormControl>
        )}
      />
    </div>
  );
  const CurrencyFormField = () => (
    <div style={{ padding: '0px 10px', width: '100%' }}>
      <Controller
        name="CapitalToDistribute"
        control={control}
        rules={{ required: 'Required' }}
        render={({ field: { value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>Capital to distribute</FormLabel>
            <NumericFormat
              type="text"
              value={value}
              customInput={TextField}
              onValueChange={e => setValue('CapitalToDistribute', e.formattedValue)}
              thousandSeparator=","
              decimalSeparator="."
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p style={{ margin: 0, color: 'black' }}>$</p>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />
    </div>
  );

  const CustomSubmitButton = ({ text }: { text: string }) => (
    <div className="col-sm-12 btn-row-black mt-3">
      <Button type="submit" variant="contained" className="col-sm-12" disabled={false}>
        {text}
      </Button>
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { control, handleSubmit, setValue, getValues, reset, formState } = useForm<DistributeCapitalGeneralForm>({
    mode: 'onChange',
    defaultValues: {
      FundCode: fundCode,
      FundName: fundName,
      CapitalToDistribute: '',
    },
    shouldFocusError: true,
  });

  return (
    <div className="dialog-form-wrap" style={{ maxWidth: 500 }}>
      <div className="title-and-subtitle-wrap">
        <p className="title">Trigger capital distribution </p>
        <p className="subtitle">Distribute capital amongst the investments stakeholders based on the waterfall model</p>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="from-row theam-black">
          <CustomFormField key="FundCode" fieldKey="FundCode" fieldName="Fund Code" size={5} disabled={true} />
          <CustomFormField key="FundName" fieldKey="FundName" fieldName="Fund Name" size={7} disabled={true} />
          <CurrencyFormField key="RequestedAmount" />
          <CustomSubmitButton text="Continue" />
        </div>
      </form>
    </div>
  );
};
export default GeneralDistributionForm;
