import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFundDocument } from 'src/services/documentsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { AxiosError } from 'axios';

export const useDeleteFundDocument = (fundID: number) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (documentID: number) => {
      return deleteFundDocument(documentID);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.Documents, 'fund', fundID] });
      await showSuccessSnackbar('The document was successfully deleted');
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
