import React, { useEffect, useState } from 'react';
import ConfirmDialogContainer from '../../modals/confirm-modal/confirmDialogContainer';
import HeaderComponent from './headerComponent';
import ChangePasswordModalContainer from '../../modals/change-password-modal/changePasswordModalContainer';
import { useNavigate } from 'react-router-dom';
import { resetNewNotificationCount, newNotificationCount } from '../../../services/notificationServices';
import { getChatMemberInfo, getTwilioToken } from '../../../services/chatService';
import { Client, Message } from '@twilio/conversations';
import { CustomConversation } from '../../../models/chatModels';
import ShareNDAAgreementContainer from '../../modals/share-nda-agreement/shareNDAAgreementContainer';
import { useAuth } from 'src/context/AuthContext';
import { UserDetails } from 'src/models/authModels';

const HeaderContainer = ({ title, subtitle }: HeaderContainerProps) => {
  const { logout, userData } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [totalNotification, setTotalNotification] = useState<number>(0);
  const [openChat, setOpenChat] = useState(false);
  const [visibleModal, setVisibleModal] = useState<string>('');
  const [twilioToken, setTwilioToken] = useState('');
  const [client, setClient] = useState<Client>();
  const [customConvo, setCustomConvo] = useState<CustomConversation>();
  const [screenVal, setScreenVal] = useState(0);

  const changePasswordModal = () => {
    setVisibleModal('change-password-modal');
  };

  const closeModal = () => {
    setVisibleModal('');
  };

  const createNdaRequestModal = () => {
    setVisibleModal('share-nda-agreement-modal');
  };

  const getChatToken = () => {
    if (userData?.userId) {
      getTwilioToken(userData.userId)
        .then(response => {
          if (response.data.status === 200) {
            setTwilioToken(response.data.data.token);
          }
        })
        .catch(() => {
          setTwilioToken('');
        });
    }
  };

  const createTwilioClient = () => {
    const client = new Client(twilioToken);
    setClient(client);

    client.on('tokenAboutToExpire', getChatToken);
    client.on('tokenExpired', getChatToken);
  };

  const messageAddedNotification = (message: Message) => {
    if (
      message.author !== userData?.userId.toString() &&
      customConvo?.conversation?.sid !== message?.conversation?.sid
    ) {
      if (!message.conversation.uniqueName) {
        //TODO: handle notifications
      } else {
        getChatMemberInfo({
          params: { uniqueName: [message.conversation.uniqueName!] },
        });
      }
    }
  };

  useEffect(() => {
    if (userData) {
      getChatToken();
    }
  }, [userData]);

  useEffect(() => {
    if (twilioToken) {
      createTwilioClient();
    }
    return () => {
      client?.removeAllListeners();
    };
  }, [twilioToken]);

  useEffect(() => {
    client?.on('messageAdded', messageAddedNotification);

    return () => {
      client?.off('messageAdded', messageAddedNotification);
    };
  }, [client, customConvo]);

  const confirmLogout = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const closeConfirmationPopUp = () => {
    setOpen(false);
  };

  const fetchNotificationCountHandler = async () => {
    if (userData?.userId)
      try {
        const result = await newNotificationCount(userData.userId);
        setTotalNotification(result?.data?.data?.latestNotifications);
      } catch (error) {
        // Empty Catch
      }
  };

  const resetNewNotificationCountHandler = async () => {
    if (userData?.userId)
      try {
        const result = await resetNewNotificationCount({
          userId: userData.userId,
        });
        setTotalNotification(result?.data?.data?.latestNotifications);
      } catch (error) {
        //TODO: add error handling
      }
  };

  useEffect(() => {
    fetchNotificationCountHandler();
    const notificationInterval = setInterval(() => {
      fetchNotificationCountHandler();
    }, 10000);

    return () => clearInterval(notificationInterval);
  }, [userData]);

  const logOut = () => {
    navigator.geolocation.getCurrentPosition(
      location => {
        logout({
          Location: `${location.coords.latitude},${location.coords.longitude}`,
        });
      },
      () => {
        logout({ Location: null });
      },
      {
        timeout: 2000,
      },
    );
  };

  const logOutHandler = () => {
    setOpen(false);
    logOut();
  };

  const navigate = useNavigate();

  const navigateToSupportRequest = () => {
    navigate('/support-requests');
  };

  const navigateToFaq = () => {
    navigate('/faq');
  };

  const navigateToMySubscription = () => {
    navigate('/my-subscriptions');
  };

  const navigateToGlobalSearch = (searchText: string) => {
    navigate(`/global-search?searchText=${searchText}`);
  };

  return (
    <>
      {visibleModal === 'change-password-modal' && (
        <ChangePasswordModalContainer isVisible={visibleModal === 'change-password-modal'} closeModal={closeModal} />
      )}
      {visibleModal === 'share-nda-agreement-modal' && (
        <ShareNDAAgreementContainer isVisible={visibleModal === 'share-nda-agreement-modal'} closeModal={closeModal} />
      )}
      <HeaderComponent
        title={title || ''}
        subtitle={subtitle || ''}
        confirmLogout={confirmLogout}
        anchorEl={anchorEl}
        setAnchorVal={setAnchorEl}
        userDetails={userData as UserDetails}
        navigateToSupportRequest={navigateToSupportRequest}
        navigateToFaq={navigateToFaq}
        navigateToMySubscription={navigateToMySubscription}
        navigateToGlobalSearch={navigateToGlobalSearch}
        totalNotification={totalNotification}
        resetNewNotificationCountHandler={resetNewNotificationCountHandler}
        client={client}
        changePasswordModal={changePasswordModal}
        openChat={openChat}
        setOpenChat={setOpenChat}
        customConvo={customConvo}
        setCustomConvo={setCustomConvo}
        screenVal={screenVal}
        setScreenVal={setScreenVal}
        createNdaRequestModal={createNdaRequestModal}
      />
      {!!open && (
        <ConfirmDialogContainer
          isVisible={open}
          title="Confirm Logout"
          dialogContentText="Are you sure you want to logout from this website?"
          confirmModal={logOutHandler}
          closeModal={closeConfirmationPopUp}
        />
      )}
    </>
  );
};

interface HeaderContainerProps {
  title?: string;
  subtitle?: string;
}

export default HeaderContainer;
