import { ReactComponent as ChevronDown } from '../../../../assets/images/icons/chevron-down.svg';
import Button from '../../../../components/common/Button';
import { getDistBreakdownFile } from '../../../../services/governanceService';
import { currentFormatNoDollarSign, getTodaysDateFormatted } from '../../../../views/utils';

const DistPaymentBreakdownAccordion = ({
  fundId,
  fundName,
  gpAmount,
  lpAmount,
  unrolled,
  setUnrolled,
}: DistPaymentBreakdownAccordionProps) => {
  const handleChevronClick = () => {
    if (unrolled) {
      setUnrolled('');
    } else {
      setUnrolled('payment-breakdown');
    }
  };

  const handleDownloadBreakdownFile = async () => {
    const response = await getDistBreakdownFile(fundId);
    // const arrayBuffer = await response.arrayBuffer();
    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      // Create a link element
      const downloadLink = document.createElement('a');

      // Create a URL for the Blob and set it as the href
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;

      // Set the download attribute with the desired file name
      downloadLink.download = `${fundName}-${getTodaysDateFormatted()}-distribution-payment-breakdown.xlsx`.replace(
        ' ',
        '-',
      );

      // Append the link to the document
      document.body.appendChild(downloadLink);

      // Trigger a click on the link to start the download
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);

      // Release the object URL
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Service call failed with status:', response.status);
    }
  };
  return (
    <div className={`review-accordion-container ${unrolled && 'unrolled-accordion'}`}>
      <div className="accordion-header">
        <div className="title-and-subtitle-wrap">
          <p className="title">Distribution payments breakdown</p>
          <p className="subtitle">Extensive breakdown of distribution payments allocation</p>
        </div>
        <div className="chevron-box" onClick={() => handleChevronClick()}>
          <ChevronDown />
        </div>
      </div>
      <div className="accordion-content">
        <div style={{ width: 800 }}>
          <div className="amount-breakdown-container">
            <div className="portions-wrapper">
              <div className="item">
                <div className="label">Total to Gp</div>
                <div className="content">$ {currentFormatNoDollarSign(gpAmount)}</div>
              </div>
              <div className="v-separator" />
              <div className="item">
                <div className="label">{"Total to LP's"}</div>
                <div className="content">$ {currentFormatNoDollarSign(lpAmount)}</div>
              </div>
            </div>
            <Button
              size="small"
              order="primary"
              onClick={() => {
                handleDownloadBreakdownFile();
              }}
            >
              Download breakdown file
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface DistPaymentBreakdownAccordionProps {
  gpAmount: number;
  lpAmount: number;
  unrolled: boolean;
  setUnrolled: React.Dispatch<React.SetStateAction<string>>;
  fundName: string;
  fundId: number;
}

export default DistPaymentBreakdownAccordion;
