import { Loader } from 'src/components/common/Loader';
import GovernanceComponent from './governanceComponent';
import { useFunds } from 'src/hooks/react-query/funds';

const GovernanceContainer = () => {
  const { data: funds, isLoading } = useFunds();

  if (isLoading) return <Loader />;

  return <GovernanceComponent funds={funds ?? []} />;
};

export default GovernanceContainer;
