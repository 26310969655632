/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddRoleComponent } from './addRoleComponent';
import { usePrivileges, useRoleById, useRoleCreate, useRoleUpdate } from 'src/hooks/react-query/roles';
import { Loader } from 'src/components/common/Loader';
import { RoleDTO, RoleForm } from './validations';
import { Privilege } from 'src/types/Roles';
import { getCheckedIds, getIdsWithPrivilege } from './utils/getPrivilegeIds';
import { Permission } from 'src/consts/permissions';

const validSlugs = Object.values(Permission);

export const AddRoleContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [privilegesState, setPrivilegesState] = useState<Privilege[][]>([]);

  const { data: privileges, isLoading } = usePrivileges();
  const { data: roleDetails, isLoading: isRoleDetailLoading } = useRoleById(params.roleId);

  const { mutateAsync: createRole, isLoading: isCreating } = useRoleCreate(() => navigate('/roles'));
  const { mutateAsync: updateRole, isLoading: isUpdating } = useRoleUpdate(params.roleId, () => navigate('/roles'));

  const privilegeList: Privilege[] = useMemo(() => (privileges ? [...privileges.privilege] : []), [privileges]);

  const handleEdit = (formData: RoleDTO) => {
    if (params.roleId) {
      updateRole(formData);
    }
  };

  const handleSave = (formData: RoleDTO) => {
    createRole(formData);
  };

  const onSubmit = async (values: RoleForm) => {
    const body = {
      Name: values.Name,
      IsActive: 1,
      Privileges_Id: getCheckedIds(privilegesState[0]),
      //TODO: add fund privileges
      FundPrivileges: [],
    };
    if (params.roleId) {
      return handleEdit(body);
    }
    handleSave(body);
  };

  const handleParentChange = (parentId: number, index: number) => {
    setPrivilegesState(prevState =>
      prevState.map((privileges, idx) => {
        if (idx === index) {
          return privileges.map(parent =>
            parent.id === parentId
              ? {
                  ...parent,
                  checked: !parent.checked,
                  ...(parent.checked && {
                    childPrivilege: parent.childPrivilege.map(child => ({
                      ...child,
                      checked: !parent.checked,
                    })),
                  }),
                }
              : parent,
          );
        }
        return privileges;
      }),
    );
  };

  const handleChildChange = (parentId: number, childId: number, index: number) => {
    setPrivilegesState(prevState =>
      prevState.map((privileges, idx) => {
        if (idx === index) {
          return privileges.map(parent => {
            if (parent.id === parentId) {
              const updatedChildren = parent.childPrivilege.map(child =>
                child.id === childId ? { ...child, checked: !child.checked } : child,
              );
              const anyChildrenChecked = updatedChildren.some(child => child.checked);
              return {
                ...parent,
                checked: parent.checked || anyChildrenChecked,
                childPrivilege: updatedChildren,
              };
            }
            return parent;
          });
        }

        return privileges;
      }),
    );
  };

  useEffect(() => {
    if (privilegeList) {
      const selectedIds = getIdsWithPrivilege(roleDetails?.privilege ?? []);
      const updatedState = privilegeList.map(parent => {
        const isParentChecked = selectedIds.includes(parent.id);
        const updatedChildren = parent.childPrivilege.map(child => ({
          ...child,
          checked: selectedIds.includes(child.id),
        }));
        const isAllChildChecked = !!updatedChildren.length && updatedChildren.every(child => child.checked);
        return {
          ...parent,
          checked: isParentChecked || isAllChildChecked,
          childPrivilege: updatedChildren,
        };
      });
      setPrivilegesState([updatedState]);
    }
  }, [roleDetails, privilegeList]);

  const filteredPrivileges: Privilege[][] = useMemo(
    () =>
      privilegesState.map(item =>
        item
          .map(parent => {
            const filteredChildren = parent.childPrivilege.filter(child =>
              validSlugs.includes(child.Slug as Permission),
            );
            if (validSlugs.includes(parent.Slug as Permission) || filteredChildren.length > 0) {
              return {
                ...parent,
                childPrivilege: filteredChildren,
              };
            }
            return null as unknown as Privilege;
          })
          .filter(parent => parent !== null),
      ),
    [privilegesState],
  );

  if (isLoading || isCreating || isUpdating || (params.roleId && isRoleDetailLoading)) return <Loader />;

  return (
    <>
      {params.roleId ? (
        <AddRoleComponent
          onSubmit={onSubmit}
          roleDetails={roleDetails}
          privilegeList={filteredPrivileges}
          onParentChange={handleParentChange}
          onChildChange={handleChildChange}
        />
      ) : (
        <AddRoleComponent
          onSubmit={onSubmit}
          privilegeList={filteredPrivileges}
          onParentChange={handleParentChange}
          onChildChange={handleChildChange}
          isNew
        />
      )}
    </>
  );
};
