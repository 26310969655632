import Button from '../../../components/common/Button';
import { useState } from 'react';
import WaterfallAccordion from './finalReviewComponents/WaterfallAccordion';
import DistPaymentBreakdownAccordion from './finalReviewComponents/DistPaymentBreakdownAccordion';
import PaymentMethodAccordion from './finalReviewComponents/PaymentMethodAccordion';

const FinalReviewDisplay = ({
  fboBalance,
  onSubmit,
  distributionAmount,
  selectedCard,
  manualPortion,
  automatedPortion,
  gpAmount,
  lpAmount,
  fundName,
  fundId,
}: FinalReviewDisplayProps) => {
  const [accordionOpen, setAccordionOpen] = useState('waterfall');

  return (
    <div
      className="dialog-form-wrap"
      style={{
        maxWidth: 1000,
        marginTop: 12,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <WaterfallAccordion
        distributionAmount={distributionAmount}
        unrolled={accordionOpen === 'waterfall'}
        setUnrolled={setAccordionOpen}
      />
      <DistPaymentBreakdownAccordion
        fundId={fundId}
        fundName={fundName}
        lpAmount={lpAmount}
        gpAmount={gpAmount}
        unrolled={accordionOpen === 'payment-breakdown'}
        setUnrolled={setAccordionOpen}
      />
      <PaymentMethodAccordion
        fboBalance={fboBalance}
        selectedCard={selectedCard}
        manualAmount={manualPortion}
        automatedAmount={automatedPortion}
        unrolled={accordionOpen === 'payment-method'}
        setUnrolled={setAccordionOpen}
      />
      <Button order="primary" size="medium" onClick={onSubmit} customStyle={{ marginTop: 24, alignSelf: 'flex-end' }}>
        Trigger capital distribution
      </Button>
    </div>
  );
};

type FinalReviewDisplayProps = {
  onSubmit?: () => Promise<void>;
  distributionAmount: number;
  selectedCard: '' | 'fbo' | 'ach';
  fboBalance: number | undefined | null;
  manualPortion: number;
  automatedPortion: number;
  gpAmount: number;
  lpAmount: number;
  fundName: string;
  fundId: number;
};

export default FinalReviewDisplay;
