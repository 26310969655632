import { Fund } from 'src/types/Funds';
import DeployCapitalCallModalComponent, { DeployCapitalCallForm } from './deployCapitalCallComponent';

const DeployCapitalCallModalContainer = ({
  isVisible,
  closeModal,
  handleOnSubmit,
  fund,
}: DeployCapitalCallModalContainerProps) => {
  const onSubmit = (formData: DeployCapitalCallForm) => {
    handleOnSubmit(formData);
    closeModal();
  };

  return (
    <DeployCapitalCallModalComponent isVisible={isVisible} closeModal={closeModal} onSubmit={onSubmit} fund={fund} />
  );
};

interface DeployCapitalCallModalContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  handleOnSubmit: (formData: DeployCapitalCallForm) => void;
  fund: Fund | null | undefined;
}

export default DeployCapitalCallModalContainer;
