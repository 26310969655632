import { useQuery } from '@tanstack/react-query';
import { getAssetKpis } from 'src/services/partnershipService';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { EmptyState } from 'src/components/common/EmptyState';
import { CardKPI } from 'src/components/CardKPI';
import { formatDateNoTime } from 'src/views/utils';
import { kpiIndicatorMapping, mapUnitType } from '../utils/kpiIndicatorMapping';
import { QueryKey } from 'src/consts/query';

export enum IndicatorType {
  KPIS = 'kpis',
  CHARTS = 'charts',
}

export const AssetKpi = () => {
  const params = useParams();
  const { showErrorSnackbar } = useSnackbar();

  const {
    data: assetKpi,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.AssetKpi, params.assetId],
    queryFn: async () => {
      if (params.assetId) {
        try {
          const responce = await getAssetKpis(params.assetId);
          return responce;
        } catch (e) {
          console.log('assetKpi error', e);
          showErrorSnackbar((e as AxiosError).message);
          throw new Error((e as AxiosError).message);
        }
      }
      return [];
    },
  });

  if (isLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <div className="asset-detail-kpis-container">
      {assetKpi.length ? (
        //TODO: get from API
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        assetKpi.map((indicator: any) => {
          if (indicator.type === IndicatorType.KPIS) {
            return (
              <>
                <div key={indicator.title} className="heading-title mt-45 mb-25">
                  <h3>{indicator.title}</h3>
                </div>
                <div className="row">
                  {
                    //TODO: get from API
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    indicator.data.map((item: any, index: any) => (
                      <div key={item.kpiKey} className="col-sm-6 col-md-4 col-xl-3 c-pointer">
                        <CardKPI
                          id={index}
                          key={item.kpiKey}
                          cardHeader={item.lastDate ? `As on ${formatDateNoTime(item.lastDate, true)}` : '-'}
                          kpiDescription={kpiIndicatorMapping(item.kpiKey, 'indicatorToKpi')}
                          value={item.kpiValue || 0}
                          ValueType={mapUnitType(item.kpiUnit)}
                          variationNumber={item.previousDateRateChange}
                        />
                      </div>
                    ))
                  }
                </div>
              </>
            );
          }
        })
      ) : (
        <EmptyState label="There are no KPI's data for the selected Asset." />
      )}
    </div>
  );
};
