import React from 'react';

interface ProgressbarProps {
  progressValue: number;
  customNodeCount?: string[];
}

const Progressbar = ({ progressValue, customNodeCount }: ProgressbarProps) => {
  const nodeCount = customNodeCount || ['Carrito', 'Entrega', 'Pago'];
  // const calcNodePosition = (nodeIndex: any) => {
  //   let pos = nodeIndex / (nodeCount.length - 1);
  //   return { left: `${pos * 100}%` };
  // };

  const calcBarFill = () => {
    return {
      background: `linear-gradient(90deg, #61C2A0  calc(0% + 44px), #61C2A0  ${
        (progressValue / (nodeCount.length - 1)) * 100
      }%, #f2f2f2 ${(progressValue / (nodeCount.length - 1)) * 100}%, #f2f2f2 100%) `,
    };
  };
  return (
    <div style={calcBarFill()} className="bar-body">
      {nodeCount.map((node, index) => {
        return (
          <div
            key={node + index}
            // style={calcNodePosition(index)}
            className={`bar-node-wrapper ${index <= progressValue ? 'active-node' : ''}`}
          >
            <div className="bar-node">
              <div className="bar-node-text">{node}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Progressbar;
