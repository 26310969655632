import { renderCurrency } from '../../../views/utils';
import Button from '../../../components/common/Button';
import FundingTypePicker from './reviewPaymentsStepComponents/FundingTypePicker';
import { DistributeCapitalGeneralForm } from './DistributeCapitalComponent';

type ReviewPaymentStructureProps = {
  onSubmit: (formData: DistributeCapitalGeneralForm) => void;
  selectedCard: 'fbo' | 'ach' | '';
  setSelectedCard: React.Dispatch<React.SetStateAction<'' | 'fbo' | 'ach'>>;
  isFboFundingOpen: boolean;
  setIsFboFundingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fboBalance: number | undefined | null;
  automatedPortion: number;
  manualPortion: number;
};

const ReviewPaymentStructure = ({
  onSubmit,
  selectedCard,
  setSelectedCard,
  // isFboFundingOpen,
  setIsFboFundingOpen,
  fboBalance,
  automatedPortion,
  manualPortion,
}: ReviewPaymentStructureProps) => {
  return (
    <div className="dialog-form-wrap" style={{ maxWidth: 500 }}>
      <div className="title-and-subtitle-wrap" style={{ marginBottom: 24 }}>
        <p className="title">Trigger capital distribution </p>
        <p className="subtitle">Distribute capital amongst the investments stakeholders based on the waterfall model</p>
      </div>
      <div className="breakdown-item">
        <div className="label">Manual payments total amount</div>
        <div className="value">{renderCurrency(manualPortion)}</div>
      </div>
      <div className="separator" />
      <div className="breakdown-item">
        <div className="label">Automated payments total amount</div>
        <div className="value">{renderCurrency(automatedPortion)}</div>
      </div>
      <FundingTypePicker
        fboBalance={fboBalance}
        automatedPaymentsAmount={automatedPortion}
        setIsFboFundingOpen={setIsFboFundingOpen}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
      />
      <div className="separator" />
      <Button
        order="primary"
        size="medium"
        customStyle={{ width: '100%', marginTop: 24 }}
        onClick={e => onSubmit(e as unknown as DistributeCapitalGeneralForm)}
        disabled={!selectedCard}
      >
        Confirm payment settings
      </Button>
    </div>
  );
};
export default ReviewPaymentStructure;
