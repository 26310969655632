import { useEffect, useReducer, useState } from 'react';
import { currentFormatNoDollarSign } from '../../views/utils';
import styles from './styles.module.scss';
import { getWaterfallState } from '../../services/governanceService';
import { useParams } from 'react-router-dom';

function waterfallReducer(state: WaterfallState, action: WaterfallAction) {
  const { type, payload } = action;
  switch (type) {
    case WaterfallActionKind.DISTRIBUTE_STACK: {
      let newPaymentStackAux = +state.newPaymentStack;
      const newLevels = state.levels.map(lev => {
        const remaining = lev.hurdle! - lev.realizedDistributions;
        if (!lev.completed) {
          if (newPaymentStackAux >= remaining) {
            newPaymentStackAux -= remaining;
            return { ...lev, completed: true, newPayment: remaining };
          } else {
            const res = {
              ...lev,
              completed: true,
              newPayment: +newPaymentStackAux,
            };
            newPaymentStackAux = 0;
            return res;
          }
        }
        return { ...lev };
      });
      return {
        ...state,
        levels: newLevels,
        newPaymentStack: newPaymentStackAux,
      };
      // return {...state}
    }

    case WaterfallActionKind.LOAD_DATA: {
      // TODO: get type from API
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newLevels = payload.levels.map((lev: any, index: number) => {
        return {
          level: index,
          hurdle: lev.hurdle,
          completed: false,
          realizedDistributions: lev.alreadyDistributedGp + lev.alreadyDistributedLp,
          newPayment: state.levels[index].newPayment,
          levelName: state.levels[index].levelName,
          shortName: state.levels[index].shortName,
          gpSection: parseFloat((lev.gpPercentage * 100).toFixed(1)),
          lpSection: parseFloat((lev.lpPercentage * 100).toFixed(1)),
          promote: parseFloat((lev.promote * 100).toFixed(1)),
          irr: parseFloat(((1 + lev.dailyInterestRate) ** 365 - 1).toFixed(2)) * 100,
        };
      });
      return { ...state, levels: newLevels };
    }
    default:
      return state;
  }
}

const WaterfallContainer = ({ distributionAmount = 0, distributeStack = false }: WaterfallContainerProps) => {
  const params = useParams();
  const [newDistOnDisplay, setNewDistOnDisplay] = useState(false);
  const [waterfallError, setWaterfallError] = useState(false);

  const [state, dispatch] = useReducer(waterfallReducer, {
    levels: [
      {
        level: 0,
        hurdle: 1000,
        completed: false,
        realizedDistributions: 0,
        newPayment: 0,
        levelName: 'Return of capital',
        shortName: 'Cap return',
        gpSection: 20,
        lpSection: 80,
        promote: 0,
        irr: 0,
      },
      {
        level: 1,
        hurdle: 200,
        completed: false,
        realizedDistributions: 0,
        newPayment: 0,
        levelName: 'Tier 1: Prorate return',
        shortName: 'Tier 1',
        gpSection: 20,
        lpSection: 80,
        promote: 0,
        irr: 12,
      },
      {
        level: 2,
        hurdle: 130,
        completed: false,
        realizedDistributions: 0,
        newPayment: 0,
        levelName: 'Tier 2: 20% Promote',
        shortName: 'Tier 2',
        gpSection: 16,
        lpSection: 64,
        promote: 20,
        irr: 15,
      },
      {
        level: 3,
        hurdle: null,
        completed: false,
        realizedDistributions: 0,
        newPayment: 0,
        levelName: 'Tier 3: 30% Promote',
        shortName: 'Tier 3',
        gpSection: 14,
        lpSection: 56,
        promote: 30,
        irr: 0,
      },
    ],
    newPaymentStack: distributionAmount,
  });

  useEffect(() => {
    const fetchWaterfallState = async () => {
      const res = await getWaterfallState(parseInt(params.fundId!));
      if (res.data.status === 200) {
        dispatch({
          type: WaterfallActionKind.LOAD_DATA,
          payload: res.data.data,
        });
        if (!newDistOnDisplay) {
          if (distributeStack) {
            setNewDistOnDisplay(true);
            dispatch({
              type: WaterfallActionKind.DISTRIBUTE_STACK,
              payload: 0,
            });
          }
        }
      } else {
        setWaterfallError(true);
      }
    };
    fetchWaterfallState();
  }, [params.fundId, newDistOnDisplay]);

  const WaterfallLevel = ({
    level,
    hurdle,
    // completed,
    realizedDistributions,
    newPayment,
    levelName,
    promote,
    gpSection,
    lpSection,
  }: levelInfoType) => {
    const calcOffsetStyles = (index: number) => {
      switch (index) {
        case 0:
          return { left: 0 };
        case 1:
          return { left: '40%', transform: 'translateX(-50%)' };
        case 2:
          return { left: '60%', transform: 'translateX(-50%)' };
        case 3:
          return { right: 0 };
      }
    };

    return (
      <div className={styles.levelWrapper} style={calcOffsetStyles(level)}>
        <div className={styles.headerWrapper}>
          <div className={styles.levelName}>{levelName}</div>
          <div className={styles.hurdleProgress}>
            {'$ ' + currentFormatNoDollarSign(realizedDistributions + newPayment, 0)}
            {hurdle !== null ? ` / ${currentFormatNoDollarSign(hurdle, 0)}` : null}
          </div>
        </div>
        <div className={styles.levelContainerBox}>
          <div
            className={styles.paymentStructureSection}
            style={{ width: `${lpSection}%`, backgroundColor: '#F6F5F4' }}
          >{`${lpSection}%`}</div>
          <div
            className={styles.paymentStructureSection}
            style={{ width: `${gpSection}%`, backgroundColor: '#ECEBEA' }}
          >{`${gpSection}%`}</div>
          {!!promote && (
            <div
              className={styles.paymentStructureSection}
              style={{ width: `${promote}%`, backgroundColor: '#E2E1E0' }}
            >{`${promote}%`}</div>
          )}
          {hurdle !== null ? (
            <>
              <div
                className={styles.bottomlineRealizedPayments}
                style={{
                  width: `${((realizedDistributions * 100) / hurdle!).toFixed(2)}%`,
                }}
              />
              <div
                className={styles.bottomlineNewPayment}
                style={{
                  width: `${(((realizedDistributions + newPayment) * 100) / hurdle!).toFixed(2)}%`,
                }}
              />
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.waterfallContainer}>
      {
        // TODO: get type from API
      }
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {state.levels.map((lev: any) => {
        return (
          <div className={styles.levelRail} key={lev.levelName + lev.level}>
            <WaterfallLevel
              key={lev.levelName}
              level={lev.level}
              hurdle={lev.hurdle}
              completed={lev.completed}
              realizedDistributions={lev.realizedDistributions}
              newPayment={lev.newPayment}
              levelName={lev.levelName}
              shortName={lev.shortName}
              gpSection={lev.gpSection}
              lpSection={lev.lpSection}
              promote={lev.promote}
              irr={lev.irr}
            />
          </div>
        );
      })}
      <div className={styles.promoteStructureContainer}>
        <div className={styles.headerRow}>
          <div className={styles.levelItem}>Level</div>
          <div className={styles.promoteItem}>Promote</div>
          <div className={styles.irrItem}>IRR</div>
        </div>
        {
          // TODO: get type from API
        }
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {state.levels.map((lev: any) => {
          return (
            <div className={styles.contentRow} key={lev.shortName}>
              <div className={styles.levelItem}>{lev.shortName}</div>
              <div className={styles.promoteItem}>{lev.promote.toFixed(2)}%</div>
              <div className={styles.irrItem}>{lev.irr.toFixed(2)}%</div>
            </div>
          );
        })}
      </div>
      {waterfallError && (
        <div className={styles.errorCover}>
          <div className={styles.displayError}>Oops!</div>
          Something went wrong when fetching waterfalls data
        </div>
      )}
    </div>
  );
};

type levelInfoType = {
  level: number;
  hurdle: number | null;
  completed: boolean;
  realizedDistributions: number;
  newPayment: number;
  levelName: string;
  shortName: string;
  promote: number;
  gpSection: number;
  lpSection: number;
  irr: number;
};
enum WaterfallActionKind {
  DISTRIBUTE_STACK = 'DISTRIBUTE_STACK',
  LOAD_DATA = 'LOAD_DATA',
  // CONSOLIDATE_PAYMENTS = 'CONSOLIDATE_PAYMENTS'
}

interface WaterfallAction {
  type: WaterfallActionKind;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

interface WaterfallState {
  levels: levelInfoType[];
  newPaymentStack: number;
}

interface WaterfallContainerProps {
  distributionAmount?: number;
  distributeStack?: boolean;
}

export default WaterfallContainer;
