import classNames from 'classnames';
import { Asset, Fund } from 'src/types/Funds';
import FundBg from 'src/assets/images/fund_bg.png';

const AssetCard = ({ asset, clickHandler }: AssetCardProps) => {
  return (
    <div className={classNames('asset-card-container', { clickable: clickHandler })} onClick={clickHandler}>
      {
        //TODO:  add bg to the asset
      }
      <div className="asset-thumbnail-container" style={{ backgroundImage: `url("${FundBg}")` }}></div>
      <div className="asset-content-wrapper">
        <div className="asset-name">{asset.Title}</div>
        <div className="asset-code">Asset code: {asset.Code}</div>
        <div className="invested-top-label">Invested amount</div>
        <div className="invested-value">$ 355,900.00</div>
      </div>
    </div>
  );
};

interface AssetCardProps {
  fund?: Fund;
  asset: Asset;
  clickHandler?: () => void;
}

export default AssetCard;
