import { useQuery } from '@tanstack/react-query';
import { getAssetDetailsById } from 'src/services/partnershipService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';

export const useAssetDetailsById = (assetId: number) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchAssetDetails = async () => {
    try {
      const responce = await getAssetDetailsById(String(assetId));
      return responce.data;
    } catch (e) {
      console.error('Fetch Asset details error', e);
      showErrorSnackbar((e as AxiosError).message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.AssetDetails, assetId],
    queryFn: () => fetchAssetDetails(),
  });
};
