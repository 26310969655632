import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { userSoftDelete } from 'src/services/adminUserServices';

export const useUserDelete = (username?: string, ...cbs: Array<() => void>) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (userId?: number) => {
      return userSoftDelete(userId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['frontendUsers'] });
      showSuccessSnackbar(`User ${username || ''} has been successfully deleted`);
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
