/* eslint-disable @typescript-eslint/no-unused-vars */
import { useModal } from 'src/hooks/useModal';
import { createCapCall, getFundDetail } from 'src/services/fundsService';
import { DeployCapitalCallForm } from 'src/uiLibrary/modals/deployCapitalCall/deployCapitalCallComponent';
import { getFboAvailable, getOpenPositionSellRequests } from 'src/services/governanceService';
import { useQuery } from '@tanstack/react-query';
import NdaAproveContainer from 'src/uiLibrary/modals/nda-approve/ndaApproveContainer';
import TransactionAproveContainer from 'src/uiLibrary/modals/transaction-approve/TransactionApproveContainer';
import MarketplaceActContainer from 'src/uiLibrary/modals/marketplace-activity/marketplaceActContainer';
import DeployCapitalCallModalContainer from 'src/uiLibrary/modals/deployCapitalCall/deployCapitalCallContainer';
import DistributeCapitalModalContainer from 'src/uiLibrary/modals/distributeCapital/DistributeCapitalContainer';
import { useMemo, useState } from 'react';
import { getAgreementRequest } from 'src/services/marketplaceService';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getFundAgreementDocuments } from 'src/services/documentsService';
import ActionsHub from 'src/components/common/ActionsHub';
import IconDeployCap from 'src/uiLibrary/icons/IconDeployCap';
import IconDistributeCap from 'src/uiLibrary/icons/IconDistributeCap';
import IconCompare from 'src/uiLibrary/icons/IconCompare';
import IconApproveTransaction from 'src/uiLibrary/icons/IconApproveTransaction';
import IconApproveNDA from 'src/uiLibrary/icons/IconApproveNDA';
import { useParams } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';

enum GovernanceDialog {
  CapCall,
  DistributeCapital,
  MPA,
  Transaction,
  Nda,
}

const hasAccessTo = (
  //TODO: add roles type from redux
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  privileges: any,
  fundName: string | undefined,
  optionIndex: number,
  subOptionIndex: number,
) => {
  if (privileges) {
    const privilege = privileges.fund_privilege.find(
      //TODO: add roles type from redux
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (privilege: any) => privilege?.fund_name === fundName,
    );
    // Just if Marketplace activity - all modal options are disable
    if (subOptionIndex === 0 && privilege) {
      if (
        privilege.privileges[optionIndex].childPrivilege[subOptionIndex].childPrivilege[0].has_privilege === 0 &&
        privilege.privileges[optionIndex].childPrivilege[subOptionIndex].childPrivilege[1].has_privilege === 0 &&
        privilege.privileges[optionIndex].childPrivilege[subOptionIndex].childPrivilege[2].has_privilege === 0
      ) {
        return false;
      }
    }
    if (privilege && privilege.privileges[optionIndex].childPrivilege[subOptionIndex].has_privilege === 1) {
      return true;
    }
  }
  return false;
};

// TODO: refactore component
export const GovernanceActionsDetails = () => {
  const params = useParams();
  const { isOpen, handleOpenModal, handleCloseModal } = useModal<GovernanceDialog>();
  const [isFboFundingOpen, setIsFboFundingOpen] = useState(false);
  const { showErrorSnackbar } = useSnackbar();

  const {
    data: fundInfo,
    isLoading: fundLoading,
    // isError: fundError,
  } = useQuery({
    queryKey: [QueryKey.FundDetails, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        try {
          const responce = await getFundDetail(Number(params.fundId));
          return responce?.data;
        } catch (e) {
          console.log('fundInfo error', e);
          showErrorSnackbar((e as AxiosError).message);
          throw new Error((e as AxiosError).message);
        }
      }
      throw new Error('No Fund was provided');
    },
  });

  const {
    data: fboBalance,
    isLoading,
    // isError,
  } = useQuery({
    queryKey: [QueryKey.FboBalanceData, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        const response = await getFboAvailable(Number(params.fundId));
        return response.data[0]?.Value;
      }
      return 0;
    },
  });

  const {
    data: counterSign,
    isLoading: isCounterSignLoading,
    // isError: isCounterSignError,
  } = useQuery({
    queryKey: [QueryKey.CounterSign, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        const response = await getAgreementRequest(Number(params.fundId));
        return response.data;
      }
      return null;
    },
  });

  const {
    data: agreementDocs,
    isLoading: isAgreementDocsLoading,
    // isError: isAgreementDocsError,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.AgreementDocsData, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        const response = await getFundAgreementDocuments(Number(params.fundId));
        return response.data;
      }
      return null;
    },
  });

  const {
    data: checkApproveTransactionsActivity,
    isLoading: isCheckApproveTransactionsActivityLoading,
    // isError: isCheckApproveTransactionsActivityError,
  } = useQuery({
    queryKey: [QueryKey.CheckApproveTransactionsActivity, params.fundId],
    queryFn: async () => {
      if (params.fundId) {
        const response = await getOpenPositionSellRequests(Number(params.fundId));
        return !!response?.data?.filter(
          // TODO: get type from API
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (transaction: any) => transaction.GPApproved === 'Open',
        ).length;
      }
      return false;
    },
  });

  const fund = fundInfo?.fund;

  const openDeployCapCallModal = () => {
    handleOpenModal(GovernanceDialog.CapCall);
  };
  const openDistributeCapitalModal = () => {
    handleOpenModal(GovernanceDialog.DistributeCapital);
  };
  const openMPAModal = () => {
    handleOpenModal(GovernanceDialog.MPA);
  };
  const openTransactionModal = () => {
    handleOpenModal(GovernanceDialog.Transaction);
  };
  const openNdaModal = () => {
    handleOpenModal(GovernanceDialog.Nda);
  };

  const handleDeployCapitalCallOnSubmit = (formData: DeployCapitalCallForm) => {
    createCapCall(formData);
    // handle On Submit
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDistributeCapitalOnSubmit = (formData: DeployCapitalCallForm) => {
    // handle On Submit
  };

  if (
    isLoading ||
    fundLoading ||
    isCheckApproveTransactionsActivityLoading ||
    isCounterSignLoading ||
    isAgreementDocsLoading
  )
    return <Loader />;

  return (
    <>
      <ActionsHub
        title={'Governance Actions'}
        list={[
          {
            actionTitle: 'Deploy Capital Call',
            icon: <IconDeployCap />,
            activity: false,
            onclick: openDeployCapCallModal,
          },

          {
            actionTitle: 'Distribute Capital',
            icon: <IconDistributeCap />,
            activity: false,
            onclick: openDistributeCapitalModal,
          },
          {
            actionTitle: 'Marketplace Activity',
            icon: <IconCompare />,
            activity: false,
            onclick: openMPAModal,
            disabled: true,
          },
          {
            actionTitle: 'Approve Transactions',
            icon: <IconApproveTransaction />,
            activity: !!checkApproveTransactionsActivity,
            onclick: openTransactionModal,
            disabled: false,
          },
          {
            actionTitle: "Approve NDA's",
            icon: <IconApproveNDA />,
            activity: !!agreementDocs.length,
            onclick: openNdaModal,
            disabled: true,
          },
        ]}
      />

      <NdaAproveContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.Nda)}
        agreementDocs={agreementDocs}
        loadNdaData={refetch}
        fund={fund}
      />

      <TransactionAproveContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.Transaction)}
        fund={fund}
      />

      <MarketplaceActContainer
        closeModal={handleCloseModal}
        isVisible={isOpen(GovernanceDialog.MPA)}
        fund={fund}
        countersignTableData={counterSign}
      />

      <DeployCapitalCallModalContainer
        isVisible={isOpen(GovernanceDialog.CapCall)}
        closeModal={handleCloseModal}
        handleOnSubmit={handleDeployCapitalCallOnSubmit}
        fund={fund}
      />

      <DistributeCapitalModalContainer
        isVisible={isOpen(GovernanceDialog.DistributeCapital)}
        closeModal={handleCloseModal}
        handleOnSubmit={handleDistributeCapitalOnSubmit}
        isFboFundingOpen={isFboFundingOpen}
        setIsFboFundingOpen={setIsFboFundingOpen}
        fund={fund}
        fboBalance={fboBalance}
      />
    </>
  );
};
