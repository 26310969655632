import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFolder, UpdateFolderBody } from 'src/services/documentsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { QueryKey } from 'src/consts/query';
import { AxiosError } from 'axios';

type UseUpdateFolderProps = {
  folderId?: number;
  oldFolderName?: string;
};

export const useUpdateFolder = ({ folderId, oldFolderName }: UseUpdateFolderProps) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (body: UpdateFolderBody) => {
      if (folderId) {
        return updateFolder(folderId, body);
      }
      throw Error('No Folder id was provided');
    },
    onSuccess: async response => {
      const folderName = response?.data?.data?.name ?? '';

      await queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === QueryKey.DocumentFolders,
      });

      await showSuccessSnackbar(`The custom folder ${oldFolderName} was successfully renamed to ${folderName}`);
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
