import { isBefore } from 'date-fns';
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserDetails } from '../../../../models/authModels';
import { CustomConversation } from '../../../../models/chatModels';
import { RootState } from '../../../../redux/store';
import ChatListItemComponent from './chatListItemComponent';

const ChatListItemContainer = ({
  userDetails,
  customConvo,
  setScreenVal,
  setCustomConvo,
}: ChatListItemContainerProps) => {
  const [lastMessage, setLastMessage] = useState<string | null>();
  const [unreadCount, setUnreadCount] = useState<number | null>();

  const initialization = async () => {
    calculateLastMessage();
  };

  const calculateLastMessage = async () => {
    const res = await customConvo?.conversation.getMessages(1);
    if (res.items.length > 0) {
      const participant = await customConvo.conversation.getParticipantByIdentity(userDetails.userId.toString());
      if (
        participant?.dateCreated &&
        res.items[0].dateCreated &&
        isBefore(participant?.dateCreated, res.items[0].dateCreated)
      ) {
        setLastMessage(res.items[0].body);
        calculateUnreadCount();
      } else {
        customConvo.conversation.setAllMessagesRead();
        setUnreadCount(0);
      }
    }
  };

  const calculateUnreadCount = async () => {
    const unreadCount = await customConvo?.conversation.getUnreadMessagesCount();
    if (unreadCount === null) {
      setUnreadCount(await customConvo?.conversation.getMessagesCount());
    } else {
      setUnreadCount(unreadCount);
    }
  };

  const setChatItem = () => {
    setCustomConvo(customConvo);
  };

  useEffect(() => {
    initialization();

    customConvo?.conversation?.on('messageAdded', calculateUnreadCount);
    customConvo?.conversation?.on('messageAdded', calculateLastMessage);
    return () => {
      customConvo?.conversation?.off('messageAdded', calculateUnreadCount);
      customConvo?.conversation?.off('messageAdded', calculateLastMessage);
    };
  }, []);

  return (
    <ChatListItemComponent
      customConvo={customConvo}
      setChatItem={setChatItem}
      setScreenVal={setScreenVal}
      lastMessage={lastMessage}
      unreadCount={unreadCount}
    />
  );
};

interface ChatListItemContainerProps {
  userDetails: UserDetails;
  customConvo: CustomConversation;
  setScreenVal: (screenNum: number) => void;
  setCustomConvo: (customConvo?: CustomConversation) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChatListItemContainer);
