import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { StateOptions, UserDetailsData } from 'src/models/frontendUserModels';
import { IconClose } from 'src/uiLibrary/icons';
import { UserPayload } from 'src/models/adminUserModels';
import { userStatus } from '../consts/userStatus';
import { IMAGE_EXTENSIONS } from 'src/consts/fileExtensions';
import { RoleItem } from 'src/models/rolesModels';
import { Loader } from 'src/components/common/Loader';
import { useUserForm } from '../utils/useUserForm';
import { useUserUpdate } from 'src/hooks/react-query/user/useUserUpdate';
import { useUserCreate } from 'src/hooks/react-query/user/useUserCreate';
import { appendFormData } from '../utils/appentFormData';

interface UserFormProps {
  states: StateOptions[];
  roleList?: RoleItem[];
  userDetails?: UserDetailsData;
  isLoading?: boolean;
}

export const UserForm = ({ states, roleList, userDetails, isLoading }: UserFormProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const { control, setValue, watch, getValues, handleSubmit } = useUserForm(userDetails);

  const { mutateAsync: updateUser } = useUserUpdate(getValues('firstname'));
  const { mutateAsync: createUser } = useUserCreate();

  const handleImageDelete = () => {
    if (inputFileRef.current?.value) {
      inputFileRef.current.value = '';
    }

    setValue('profileimage', '');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0]?.name) {
        const extension = e.target.files[0].name.toLowerCase().split('.').pop();
        if (extension && IMAGE_EXTENSIONS.includes(extension)) {
          setValue('profileimage', e.target.files[0]);
        } else {
          alert('Please choose valid image');
        }
      }
    }
  };

  const onSubmit = async (data: UserPayload) => {
    const formData = new FormData();

    appendFormData(formData, data, userDetails);

    if (userDetails) {
      await updateUser(formData);
    } else {
      await createUser(formData);
    }

    navigate('/users');
  };

  if (isLoading) return <Loader />;

  const isMainGp = userDetails?.RoleSlug === 'main-gp';

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid container className="formContainer" spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  First name <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="firstname"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  Last name <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormLabel>
                Contact number <span className="require">*</span>
              </FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="countryCode"
                      control={control}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-required-label"
                            required
                            displayEmpty
                            className="formControlSelect"
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...field}
                            onChange={event => {
                              field.onChange(event);
                            }}
                            error={!!fieldState.error}
                          >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value="1">+1</MenuItem>
                          </Select>
                          {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={7.8}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="contactnumber"
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="text"
                          inputProps={{ pattern: '[0-9]*' }}
                          required
                          onChange={event => {
                            const { value } = event.target;

                            if (/^\d*$/.test(value)) {
                              field.onChange(event);
                            }
                          }}
                          className={'phoneTextField'}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : ''}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>Email address {!userDetails && <span className="require">*</span>}</FormLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                      disabled={!!userDetails}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel id="demo-simple-select-required-label">
                  State <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="state"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        className="formControlSelect"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...field}
                        onChange={event => {
                          field.onChange(event);
                        }}
                        error={!!fieldState.error}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {states?.map((state: StateOptions) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  City <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="city"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  Zip Code <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="pinCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  Address <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="address"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      required
                      onChange={event => {
                        field.onChange(event);
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : ''}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" className="formControl">
                <FormLabel id="demo-simple-select-required-label">Profile picture</FormLabel>
                <Grid container>
                  <Grid item xs={6}>
                    <Paper variant="outlined" square className="paperImage">
                      <Paper variant="outlined" square className="paperImage2">
                        {watch('profileimage') ? (
                          <div className="upload-img-wrap">
                            <img
                              src={
                                typeof watch('profileimage') === 'string'
                                  ? watch('profileimage')
                                  : URL.createObjectURL(watch('profileimage'))
                              }
                              alt="profile"
                            />
                            <IconButton onClick={() => handleImageDelete()} className="upload-img-close" size="medium">
                              <IconClose />
                            </IconButton>
                          </div>
                        ) : (
                          <AddAPhotoIcon
                            sx={{
                              fontSize: 40,
                              color: '#D9D9D9',
                              marginTop: '35px',
                            }}
                          />
                        )}
                      </Paper>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    {!watch('profileimage') && <Typography className="noPicText">no picture selected</Typography>}
                    <Button variant="contained" component="label" className={'uploadButton'}>
                      Upload
                      <input type="file" name="profilePicture" hidden onChange={handleFileChange} ref={inputFileRef} />
                    </Button>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" className="formControl">
                <FormLabel>
                  Date of birth <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="DateOfBirth"
                  control={control}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        maxDate={new Date()}
                        mask="__/__/__"
                        label=""
                        {...field}
                        inputFormat="MM/dd/yy"
                        renderInput={params => (
                          <TextField
                            InputProps={{
                              readOnly: true,
                            }}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : ''}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormControl>
              <Grid item>
                <FormControl fullWidth variant="outlined" className="formControl">
                  <FormLabel id="demo-simple-select-required-label">
                    Select role <span className="require">*</span>
                  </FormLabel>
                  <Controller
                    name="roleId"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-required-label"
                          className="formControlSelect"
                          required
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...field}
                          onChange={event => {
                            field.onChange(event);
                          }}
                          disabled={isMainGp}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={''}>Select</MenuItem>
                          {roleList?.map(userType => (
                            <MenuItem key={userType.id} value={userType.id}>
                              {userType.Name}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <FormControl fullWidth variant="outlined" className="formControlStatus">
                <FormLabel id="demo-simple-select-required-label">
                  Select status <span className="require">*</span>
                </FormLabel>
                <Controller
                  name="isactive"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-required-label"
                        className="formControlSelect"
                        required
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...field}
                        onChange={event => {
                          field.onChange(event);
                        }}
                        disabled={isMainGp}
                        error={!!fieldState.error}
                      >
                        {userStatus.map(status => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className={'btnContainer'}>
              <Button
                className={'mr-10 submitUserBtn'}
                disabled={isLoading}
                variant={'contained'}
                startIcon={<PersonAddAltIcon />}
                type="submit"
              >
                {userDetails ? 'Update User' : 'Add user'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </form>
  );
};
