import { Privilege, RoleById } from 'src/types/Roles';

export const getCheckedIds = (items: Privilege[] | Privilege['childPrivilege']): number[] => {
  return items.flatMap(item => {
    const childIds = (item as Privilege).childPrivilege ? getCheckedIds((item as Privilege).childPrivilege) : [];
    return item.checked ? [item.id, ...childIds] : childIds;
  });
};

export const getIdsWithPrivilege = <T extends RoleById['privilege']>(items: T) => {
  let ids: number[] = [];

  items.forEach(item => {
    if (item.has_privilege === 1) {
      ids.push(item.id);
    }
    if (item.childPrivilege && item.childPrivilege.length > 0) {
      ids = ids.concat(getIdsWithPrivilege(item.childPrivilege as unknown as RoleById['privilege']));
    }
  });

  return ids;
};
