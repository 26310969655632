import { Dialog, DialogContent, InputAdornment, InputProps } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { Controller, useForm } from 'react-hook-form';
import IconArrowLeft from '../../icons/IconArrowLeft';
import { useRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { IconClose } from '../../icons/IconClose';
import IconDocumentAdd from '../../icons/IconDocumentAdd';
import IconCalendar from '../../icons/IconCalendar';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RootState } from '../../../redux/store';
import { UserDetails } from '../../../models/authModels';
import { connect } from 'react-redux';
import { Fund } from 'src/types/Funds';

const DeployCapitalCallModalComponent = ({
  isVisible,
  closeModal,
  fund,
  onSubmit,
  userDetails,
}: DeployCapitalCallModalComponentProps) => {
  const [uploadedFilesLocal, setUploadedFilesLocal] = useState<File[]>([]);
  const expiryRef = useRef<HTMLInputElement>(null);
  // TODO: get type from dropzone
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropzoneRef = useRef<any | null>(null);
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const handleRemoveFileOnClick = (fileName: string) => {
    const filteredFiles = uploadedFilesLocal.filter(file => file.name !== fileName);
    setUploadedFilesLocal(filteredFiles);
  };

  const { control, handleSubmit, setValue, getValues, reset, formState } = useForm<DeployCapitalCallForm>({
    mode: 'onChange',
    defaultValues: {
      FundCode: '',
      FundName: '',
      RequestedAmount: '',
      ClosingDate: null,
      CapCallDoc: [],
    },
    shouldFocusError: true,
  });

  const onSubmitLocal = (formData: DeployCapitalCallForm) => {
    // we set the requested amount without format
    const requestedAmountFromUser = getValues('RequestedAmount');
    const requestedAmountWithoutFormat = parseFloat(requestedAmountFromUser.toString().replace(/,/g, ''));
    formData['RequestedAmount'] = `${requestedAmountWithoutFormat}`;
    formData['FundId'] = fund?.id;
    formData['UserId'] = userDetails.userId;
    onSubmit(formData);
  };

  const handleOnClose = () => {
    setTimeout(() => {
      reset();
      setUploadedFilesLocal([]);
    }, 500);
    closeModal();
  };

  useEffect(() => {
    if (fund) {
      setValue('FundCode', fund?.fundCode);
      setValue('FundName', fund?.name);
    }
  }, [fund]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      setTimeout(() => {
        reset();
        setUploadedFilesLocal([]);
      }, 500);
    }
  }, [formState, reset]);

  const handleOnDrop = (files: File[]) => {
    // Filter those uploaded files whose extension is not allowed.
    const newlyUploadedFiles = files.filter(currentFile => {
      const extension = currentFile.name.toLowerCase().split('.').pop();
      // TODO: set which extensions are allowed
      if (extension && ['png', 'pdf', 'jpeg', 'jpg', 'zip'].includes(extension)) return true;
      return false;
    });

    // Concat the previously uploaded files with the new ones.
    const updatedUploadedFiles = uploadedFilesLocal.slice().concat(newlyUploadedFiles);

    setValue('CapCallDoc', updatedUploadedFiles);
    setUploadedFilesLocal(updatedUploadedFiles);
  };

  const UploadFilesDropzone = () => (
    <div className="col-lg-12 col-md-12 col-sm-12 mt-1">
      <Dropzone
        ref={dropzoneRef}
        onDrop={handleOnDrop}
        // If one or more items were uploaded, only allow to add more with the "add" button
        noClick={uploadedFilesLocal.length > 0 ? true : false}
        noKeyboard={uploadedFilesLocal.length > 0 ? true : false}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {!uploadedFilesLocal.length && (
              <>
                <div className="dropzone-text">Click here to add files</div>
                <div className="dropzone-subtext">or</div>
                <div className="dropzone-text">Drop them here</div>
              </>
            )}
            <div className="dropzone-uploaded-files-wrapper">
              {uploadedFilesLocal.map(file => (
                <div className="dropzone-uploaded-file-or-add-file-wrapper">
                  <div className="dropzone-uploaded-file">
                    <div
                      className="dropzone-uploaded-file-remove-button"
                      onClick={() => handleRemoveFileOnClick(file.name)}
                    >
                      <IconClose />
                    </div>
                  </div>
                  <p style={{ color: '#8F8F8F' }}>{file.name}</p>
                </div>
              ))}
              {uploadedFilesLocal.length > 0 && (
                <div className="dropzone-uploaded-file-or-add-file-wrapper">
                  <div className="dropzone-upload-new-file" onClick={() => openDialog()}>
                    <IconDocumentAdd />
                  </div>
                  <p>add file</p>
                </div>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );

  const CustomFormField = ({
    fieldKey,
    fieldName,
    placeholder,
    size,
    inputProps,
  }: {
    fieldKey: keyof DeployCapitalCallForm;
    fieldName: string;
    placeholder?: string;
    size: number;
    inputProps?: InputProps;
  }) => (
    <div className={`col-sm-${size}`}>
      <Controller
        name={fieldKey}
        control={control}
        rules={{ required: 'Required' }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>{fieldName}</FormLabel>
            <TextField
              fullWidth
              type="text"
              placeholder={placeholder || ''}
              onChange={onChange}
              value={value}
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={inputProps}
            />
          </FormControl>
        )}
      />
    </div>
  );

  const CalendarFormField = () => (
    <div className="col-sm-5">
      <FormControl fullWidth variant="outlined" className="dialogFormControl calendarField">
        <FormLabel>Closing Date</FormLabel>
        <Controller
          name="ClosingDate"
          control={control}
          rules={{
            required: 'Required',
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                {...field}
                inputFormat="MM/dd/yyyy"
                value={field.value}
                onChange={date => {
                  setValue('ClosingDate', date, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                components={{
                  OpenPickerIcon: IconCalendar,
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: undefined,
                    }}
                  />
                )}
                inputRef={expiryRef}
              />
            </LocalizationProvider>
          )}
        />
      </FormControl>
    </div>
  );

  const CurrencyFormField = () => (
    <div className="col-sm-7">
      <Controller
        name="RequestedAmount"
        control={control}
        rules={{ required: 'Required' }}
        render={({ field: { value }, fieldState: { error } }) => (
          <FormControl fullWidth variant="outlined" className="dialogFormControl">
            <FormLabel>Requested Amount</FormLabel>
            <NumericFormat
              type="text"
              value={value}
              customInput={TextField}
              onValueChange={e => setValue('RequestedAmount', e.formattedValue)}
              thousandSeparator=","
              decimalSeparator="."
              error={!!error}
              helperText={error ? error.message : null}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p style={{ margin: 0, color: 'black' }}>$</p>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
      />
    </div>
  );

  const CustomSubmitButton = ({ text }: { text: string }) => (
    <div className="col-sm-12 btn-row-black mt-3">
      <Button type="submit" variant="contained" className="col-sm-12" disabled={false}>
        {text}
      </Button>
    </div>
  );

  return (
    <Dialog
      open={isVisible}
      onClose={handleOnClose}
      sx={{
        backdropFilter: 'blur(20px)',
      }}
      className="dialog-no-padding"
    >
      <DialogContent className="generic-form-dialog-content">
        <div className="dialog-content">
          <div className="back-login" onClick={handleOnClose}>
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            <p className="back-text">Back</p>
          </div>
          <div className="title-and-subtitle-wrap">
            <p className="title">Create Capital Call</p>
            <p className="subtitle">Create a capital call request to get funds from the limited partners</p>
          </div>
          <div className="dialog-form-wrap">
            <form autoComplete="off" onSubmit={handleSubmit(onSubmitLocal)}>
              <div className="from-row theam-black">
                <CustomFormField key="FundCode" fieldKey="FundCode" fieldName="Fund Code" size={5} />
                <CustomFormField key="FundName" fieldKey="FundName" fieldName="Fund Name" size={7} />
                <CurrencyFormField key="RequestedAmount" />
                <CalendarFormField key="ClosingDate" />
                <UploadFilesDropzone key="UploadedFiles" />
                <CustomSubmitButton text="Deploy capitall call" />
              </div>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface DeployCapitalCallModalComponentProps {
  isVisible: boolean;
  closeModal: () => void;
  onSubmit: (formData: DeployCapitalCallForm) => void;
  fund: Fund | null | undefined;
  userDetails: UserDetails;
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

export default connect(mapStateToProps)(DeployCapitalCallModalComponent);

export interface DeployCapitalCallForm {
  FundCode: string | undefined;
  FundName: string;
  RequestedAmount: string;
  ClosingDate: Date | null;
  CapCallDoc: File[];
  FundId: number | undefined;
  UserId: number;
}

export interface SellPositionRequestForm {
  fund: Fund;
  EstimateValue: string | undefined;
  RequestedAmount: string;
  Stake: string;
}
