import { Fund } from 'src/types/Funds';
import { DeployCapitalCallForm } from '../deployCapitalCall/deployCapitalCallComponent';
import DistributeCapitalModalComponent from './DistributeCapitalComponent';

const DistributeCapitalModalContainer = ({
  isVisible,
  closeModal,
  handleOnSubmit,
  isFboFundingOpen,
  setIsFboFundingOpen,
  fund,
  fboBalance,
}: DistributeCapitalModalContainerProps) => {
  const onSubmit = (formData: DeployCapitalCallForm) => {
    handleOnSubmit(formData);
    closeModal();
  };

  return (
    <DistributeCapitalModalComponent
      isVisible={isVisible}
      closeModal={closeModal}
      onSubmit={onSubmit}
      isFboFundingOpen={isFboFundingOpen}
      setIsFboFundingOpen={setIsFboFundingOpen}
      fund={fund}
      fboBalance={fboBalance}
    />
  );
};

interface DistributeCapitalModalContainerProps {
  isVisible: boolean;
  closeModal: () => void;
  handleOnSubmit: (formData: DeployCapitalCallForm) => void;
  isFboFundingOpen: boolean;
  setIsFboFundingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fund: Fund | null | undefined;
  fboBalance: number | undefined | null;
}

export default DistributeCapitalModalContainer;
