export enum Permission {
  Dashboard = 'dashboard',
  MyFunds = 'funds',
  Documents = 'documents',
  RoleManagement = 'role-management',
  FrontendUserManagement = 'user-management',
  CustomFolderManagement = 'custom-folder-management',
  DocumentsAdd = 'documents-add',
  DocumentsDelete = 'documents-delete',
  DocumentsEdit = 'documents-edit',
  ManageRole = 'manage-role',
  ManageUser = 'manage-user',
}
