export enum QueryKey {
  Funds = 'funds',
  Fund = 'fund',
  MyFunds = 'myFunds',
  FundInfo = 'fundInfo',
  FundDetailsData = 'fundDetailsData',
  FundDetails = 'fundDetails',
  FundDetailsById = 'fundDetailsById',
  FundsPerformanceInformation = 'fundsPerformanceInformation',
  FundsPerformanceInformationData = 'fundsPerformanceInformationData',
  FundsSpotlightChart = 'fundsSpotlightChart',
  FundsSpotlightChartData = 'fundsSpotlightChartData',
  DocumentFolders = 'DocumentFolders',
  ActiveCapCall = 'activeCapCall',
  CapitalStructureWidget = 'capitalStructureWidget',
  CapitalStructureData = 'capitalStructureData',
  AssetDetails = 'assetDetails',
  AssetDetailsData = 'assetDetailsData',
  PartnershipDetails = 'partnershipDetails',
  AssetKpi = 'assetKpi',
  Tenant = 'tenant',
  Entity = 'entity',
  EntityInfo = 'entityInfo',
  BankAccounts = 'bankAccounts',
  BankAccountsData = 'bankAccountsData',
  ApprovedPayments = 'approvedPayments',
  ApprovedPaymentsStatus = 'approvedPaymentsStatus',
  RejectedPayments = 'rejectedPayments',
  RejectedPaymentsStatus = 'rejectedPaymentsStatus',
  PendingPayments = 'pendingPayments',
  PendingPaymentsStatus = 'pendingPaymentsStatus',
  FundRequestedUsers = 'fundRequestedUsers',
  FundRequestedUsersStatus = 'fundRequestedUsersStatus',
  PendingApprovals = 'pendingApprovals',
  PendingApprovalsStatus = 'pendingApprovalsStatus',
  FundsSpotlightIndicators = 'fundsSpotlightIndicators',
  PortfolioKpisData = 'portfolioKpisData',
  FundDocuments = 'fundDocuments',
  Documents = 'documents',
  FboBalance = 'fboBalance',
  FboBalanceStatus = 'fboBalanceStatus',
  FboBalanceData = 'fboBalanceData',
  FboAvailable = 'fboAvailable',
  RelevantIndicators = 'relevantIndicators',
  CounterSign = 'counterSign',
  CounterSignStatus = 'counterSignStatus',
  AgreementDocs = 'agreementDocs',
  AgreementDocsData = 'agreementDocsData',
  CheckApproveTransactionsActivity = 'checkApproveTransactionsActivity',
  Kpi = 'kpi',
  FundById = 'fundById',
  Indicators = 'indicators',
  IndicatorsData = 'indicatorsData',
  LimitedPartners = 'limitedPartners',
  CapitalStructure = 'capitalStructure',
  CapitalStructureStatus = 'capitalStructureStatus',
  AutoLogoutTime = 'autoLogoutTime',
}
