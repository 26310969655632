import { useRef, useState } from 'react';
import { FundSlideInfo } from './components/FundSlideInfo';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Slide from '@mui/material/Slide';
import { Fund } from 'src/types/Funds';
import { useFunds } from 'src/hooks/react-query/funds/useFunds';
import { Loader } from 'src/components/common/Loader';
import { QueryError } from 'src/components/common/QueryError';

const MyFundsComponent = () => {
  const navigate = useNavigate();
  const [openFundInfoSlide, setOpenFundInfoSlide] = useState(false);
  const activeFundId = useRef<number | null>(null);

  const { data: funds, isLoading, isError } = useFunds();

  const renderFundCard = (item: Fund) => {
    return (
      <div
        className={classNames('fund-selection-card', { active: item.id === activeFundId.current })}
        onClick={() => !openFundInfoSlide && changeFundView(item.id)}
      >
        <div className="fund-header-tag">Fund</div>
        <div className="fund-title">{item.name}</div>
        <div className="fund-subtitle" dangerouslySetInnerHTML={{ __html: item.description }}></div>
        <div className="asset-count-box">
          Assets<span>{item.fundAssets.length}</span>
        </div>
      </div>
    );
  };

  const handleCloseDrawer = () => {
    setOpenFundInfoSlide(false);
  };

  const changeFundView = (fundId: number) => {
    setOpenFundInfoSlide(true);
    activeFundId.current = fundId;
  };

  const viewDetailHandler = (id: number) => {
    navigate('/my-funds/detail/' + id);
  };

  if (isLoading) return <Loader />;

  if (isError) return <QueryError />;

  if (!funds?.length && !isLoading) {
    return (
      <div className="main-body-content-frame">
        <h1>You don’t have any Funds yet</h1>
      </div>
    );
  }

  return (
    <>
      <div className="content-wrap">
        <div className="fund-cards-container">{funds?.map(renderFundCard)}</div>
      </div>

      {openFundInfoSlide && (
        <Slide direction="up" in={openFundInfoSlide}>
          <FundSlideInfo
            handleClose={handleCloseDrawer}
            viewDetailHandler={viewDetailHandler}
            fundId={activeFundId.current}
          />
        </Slide>
      )}
    </>
  );
};

export default MyFundsComponent;
