import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { updateUser } from 'src/services/adminUserServices';

export const useUserUpdate = (userName?: string, ...cbs: Array<() => void>) => {
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (formData: FormData) => updateUser(formData),
    onSuccess: async () => {
      showSuccessSnackbar(`User ${userName || ''} has been successfully updated`);
      cbs.forEach(cb => cb());
    },
    onError: e => {
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
