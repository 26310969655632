import { useQuery } from '@tanstack/react-query';
import { getIndicators } from 'src/services/governanceService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { determineKpiFeName, determineValueType, isValidGovernanceKpi } from 'src/views/governance-fund-detail/utils';
import { AxiosError } from 'axios';
import { KPIValueType } from 'src/models/partnershipModel';
import { QueryKey } from 'src/consts/query';

export const useGovernanceKpi = (fundId: string | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: [QueryKey.Indicators, fundId],
    queryFn: async () => {
      if (fundId) {
        try {
          const response = await getIndicators(Number(fundId));
          if (response?.data) {
            const indicatorsMapped = response.data
              .filter((ind: KPI) => isValidGovernanceKpi(ind.KPI))
              .map((i: KPI) => ({
                ...i,
                id: +i.index,
                KPI_FE: determineKpiFeName(i.KPI), // name that'll be displayed on both cards and charts.
                value: determineValueType(i.unit) === KPIValueType.PERCENTAGE ? i.value * 100 : i.value,
              }));

            return indicatorsMapped;
          }
        } catch (error) {
          showErrorSnackbar((error as AxiosError).message);
          console.error('showErrorSnackbar error', error);
          throw new Error((error as AxiosError).message);
        }
      }
      showErrorSnackbar('No Fund was provided');
    },
  });
};

// TODO: get type from API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KPI = any;
