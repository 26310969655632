import React from 'react';
import { Avatar, Button, IconButton } from '@mui/material';
import IconArrowLeft from '../../../icons/IconArrowLeft';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { UserDetails } from '../../../../models/authModels';
import getInitials from '../../../getInitials/getInitials';

const DeleteParticipantComponent = ({
  userDetails,
  setOpenModal,
  participantList,
  removeFromGroup,
}: DeleteParticipantComponentProps) => {
  return (
    <div className="add-delete-chat-modal">
      <div className="back-wrap ml">
        <IconButton size="large" component="span" onClick={() => setOpenModal(0)}>
          <IconArrowLeft />
        </IconButton>
        <h4 className="back-text">Delete User</h4>
      </div>
      <div className="chat-list-wrap">
        <div className="form-wrap">
          {!!participantList && participantList.length > 0 && (
            <div className="addlist-h">
              <PerfectScrollbar>
                {participantList?.map(participant => (
                  <div className="chat-list" key={participant.sid}>
                    <div className="media">
                      <span className="avater-icon">
                        <Avatar className="d-flex align-self-center" src={participant.profileImage}>
                          {getInitials(participant.userName)}
                        </Avatar>
                      </span>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <h6 className="mb-0 flex-grow-1">{participant.userName}</h6>
                          <span>
                            {participant.identity !== userDetails.userId.toString() ? (
                              <Button
                                variant="text"
                                color="error"
                                className="chat-action-button"
                                onClick={() => removeFromGroup(participant.sid)}
                              >
                                Delete
                              </Button>
                            ) : (
                              <Button
                                disabled
                                variant="text"
                                color="error"
                                className="chat-action-button"
                                onClick={() => removeFromGroup(participant.sid)}
                              >
                                Delete
                              </Button>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </PerfectScrollbar>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface DeleteParticipantComponentProps {
  userDetails: UserDetails;
  setOpenModal: (openModal: number) => void;
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  participantList?: any[];
  removeFromGroup: (memberSid: string) => void;
}

export default DeleteParticipantComponent;
