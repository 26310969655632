import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardKPI } from 'src/components/CardKPI';
import BarChart from 'src/components/charts/BarChart/BarChart';
import { IndicatorsData } from 'src/models/partnershipModel';
import { Fund } from 'src/types/Funds';

interface FundsSpotlightComponentProps {
  funds: Fund[];
  selectedFundId: number | null;
  handleOnChangeSelectedFund: (fundId: number | null) => void;
  indicators: IndicatorsData[];
  // TODO: get type from API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any[];
}

export const FundsSpotlightComponent = ({
  funds,
  selectedFundId,
  handleOnChangeSelectedFund,
  indicators,
  chartData,
}: FundsSpotlightComponentProps) => {
  const fundsDataAvailable = useMemo(() => funds && funds.length > 0, [funds]);

  const selectRef = useRef<HTMLSelectElement>(null);

  const navigate = useNavigate();

  const handleVisitFund = () => {
    if (!selectedFundId) return;

    navigate(`/my-funds/detail/${selectedFundId}`);
  };

  const handleChangeFund = () => {
    const fundId = selectRef.current?.value;

    if (!fundId) return;

    handleOnChangeSelectedFund(+fundId);
  };

  return (
    <div className="funds-spotlight-component">
      {fundsDataAvailable ? (
        <>
          <div className="funds-spotlight-header">
            <div className="funds-spotlight-header-title">
              <h2>Funds Spotlight</h2>
              <button type="button" className="funds-spotlight-header-textbutton" onClick={handleVisitFund}>
                visit fund
              </button>
            </div>
            <label>
              <select value={String(selectedFundId)} ref={selectRef} onChange={handleChangeFund}>
                {funds.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="funds-spotlight-body">
            <div className="funds-spotlight-body-kpis-container">
              {indicators.length > 0 ? (
                indicators.map(({ id, Title, SubTitle, Value, ValueType, Variance }) => (
                  <CardKPI
                    key={id}
                    id={id}
                    cardHeader={Title}
                    kpiDescription={SubTitle}
                    value={Value || 0}
                    ValueType={ValueType}
                    variationNumber={Variance}
                  />
                ))
              ) : (
                <p>There is no KPIs information to be shown for the selected fund.</p>
              )}
            </div>
            {indicators.length > 0 && (
              <div className="funds-spotlight-body-chart-container">
                <div className="funds-spotlight-body-chart-header">
                  <p>Average Cap Rate</p>
                </div>
                <div className="funds-spotlight-body-chart-data">
                  {chartData.length > 0 ? (
                    <BarChart
                      chartData={chartData}
                      margin={{ top: 0, right: 0, left: -25, bottom: 0 }}
                      tooltipFormatter={(data: number) => `${(data || 0).toFixed(2)}%`}
                    />
                  ) : (
                    <p>No data to be shown.</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <p>There are no funds.</p>
      )}
    </div>
  );
};
