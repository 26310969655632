import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { getAssetDetailsById } from 'src/services/partnershipService';

export const useAssetById = (assetId: string | undefined) => {
  const { showErrorSnackbar } = useSnackbar();

  return useQuery({
    queryKey: ['assetDetails', assetId],
    queryFn: async () => {
      if (assetId) {
        try {
          const responce = await getAssetDetailsById(assetId);
          return responce?.data;
        } catch (e) {
          console.log('useAssetById error', e);
          showErrorSnackbar((e as AxiosError).message);
          throw new Error((e as AxiosError).message);
        }
      }
      throw new Error('No Asset was provided');
    },
  });
};
