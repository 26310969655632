import React, { useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { TabPanel, a11yTableProps } from 'src/components/common/TabPanel';
import { LocationWidget } from './components/LocationWidget';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAssetDetailsById, partnershipDetailsById } from 'src/services/partnershipService';
import { FloorPlanWidget } from './components/FloorPlanWidget';
import { AssetInfoWidget } from './components/AssetInfoWidget';
import { BannerWidget } from './components/BannerWidget';
import { AssetKpi } from './components/AssetKPI';
import { Loader } from 'src/components/common/Loader';
import { Documents } from './components/Documents';
import { Tenant } from './components/Tenant';
import { createNda, sendNdaAgreementRequest } from 'src/services/ndaAgreementService';
import { AxiosError } from 'axios';
import { useSnackbar } from 'src/context/ShackbarContext';
import { MainLink } from './components/MainLink';
import { QueryError } from 'src/components/common/QueryError';
import classNames from 'classnames';
import { QueryKey } from 'src/consts/query';
import { useAuth } from 'src/context/AuthContext';
import assetImage from 'src/assets/images/asset-default-image.png';

//TODO: Remove if necessary show documents/tenant table
const IS_DOCUMENTS_TABLE_VISIBLE = false;

const PartnershipAssetsDetailsComponent = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const { userData } = useAuth();

  const [tableValue, setTableValue] = useState(0);

  const {
    data: assetDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QueryKey.AssetDetails, params.assetId],
    queryFn: async () => {
      if (params.assetId) {
        try {
          const responce = await getAssetDetailsById(params.assetId);
          return responce?.data;
        } catch (e) {
          console.log('assets error', e);
          showErrorSnackbar((e as AxiosError).message);
          throw new Error((e as AxiosError).message);
        }
      }
      throw new Error('No Asset was provided');
    },
  });

  // TODO: remove partnershipDetails dependency
  const {
    data: partnershipDetails,
    isLoading: partnershipDetailsLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isError: partnershipDetailsError,
    refetch,
  } = useQuery({
    queryKey: [QueryKey.PartnershipDetails, params.partnershipId],
    queryFn: async () => {
      if (params.partnershipId) {
        try {
          const responce = await partnershipDetailsById(params.partnershipId);
          return responce?.data;
        } catch (e) {
          console.log('partnershipDetails error', e);
          showErrorSnackbar((e as AxiosError).message);
          throw new Error((e as AxiosError).message);
        }
      }
      return {};
    },
  });

  const shareNDARequestMutation = useMutation({
    mutationFn: async (partnershipId: string) => {
      if (userData) {
        const result = await createNda({
          PartnershipId: partnershipId,
          RequestedUserId: userData?.userId,
          UserId: partnershipDetails.userId,
        });
        return result;
      }
    },
    onSuccess: responce => {
      showSuccessSnackbar('Share NDA request has been sended successfully');
      sendNdaAgreementMutation.mutate({ id: responce.data.id, payload: {} });
      refetch();
    },
    onError: e => {
      console.log('shareNDARequestMutation error', e);
      showErrorSnackbar((e as AxiosError).message);
    },
  });

  const sendNdaAgreementMutation = useMutation({
    mutationFn: ({ id, payload }: { id: number; payload: unknown }) => {
      return sendNdaAgreementRequest(id, payload);
    },
    onSuccess: () => {
      showSuccessSnackbar('NDA agreement has been sended successfully');
    },
    onError: e => {
      console.log('sendNdaAgreementMutation error', e);
      showErrorSnackbar((e as AxiosError).message);
    },
  });

  const shareNDARequestHandler = async () => {
    if (params.partnershipId && partnershipDetails && userData) {
      shareNDARequestMutation.mutate(params.partnershipId);
    }
  };

  const handleTableChange = (_: React.SyntheticEvent, newValue: number) => {
    setTableValue(newValue);
  };

  const externalId = assetDetails?.ExternalId || Number(params.externalId) || undefined;

  if (isLoading || partnershipDetailsLoading) return <Loader />;
  if (isError) return <QueryError />;

  return (
    <>
      <div className="back-button-and-breadcrumb">
        <div className="back-login" onClick={() => navigate(-1)}>
          <div className="back-text">
            <IconButton size="large" component="span">
              <IconArrowLeft stroke="#8F8F8F" />
            </IconButton>
            Back
          </div>
        </div>
        <Breadcrumbs className="breadcrumb">
          {!location.pathname.includes('marketplace') && (
            <>
              <Link color="inherit" to="/my-funds">
                My Funds
              </Link>
              {' / '}
              <Link color="inherit" to={`/my-funds/detail/${params.fundId}`}>
                {/* TODO: find better solution for fundName in url props */}
                {params.fundName}
              </Link>
            </>
          )}

          <Typography>{assetDetails?.Title}</Typography>
        </Breadcrumbs>
      </div>
      <div
        className="image-body-container"
        style={{
          backgroundImage: `url('${assetDetails?.AssetsImages?.[0]?.ImagePath ?? assetImage}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      <div className="dtl-wrapper">
        <BannerWidget asset={assetDetails} />

        <div className="box-wrapper mt-25">
          <div className="graph-wrapper map-row">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <LocationWidget asset={assetDetails} />
              </div>
              <div className="col-lg-6 col-md-12">
                <FloorPlanWidget asset={assetDetails} />
              </div>
            </div>
          </div>
          <div className="row">
            {externalId && (
              <div className="col-md-12 col-lg-6 mb-25">
                <AssetInfoWidget ExternalId={externalId} ExternalExtractorUrl={assetDetails.ExternalExtractorUrl} />
              </div>
            )}

            <div
              className={classNames('col-md-12  mb-25', {
                'col-lg-6': params.kpiRole && params.externalId,
                'col-lg-12': !(params.kpiRole && params.externalId),
              })}
            >
              <MainLink
                title="Documents"
                description="Explore asset documents"
                to={`/documents/fund/${params.fundId}/asset/${params.assetId}`}
              />
            </div>
          </div>

          {/*TODO: change page layout */}
          <div className="relative mb-30">
            <AssetKpi />
          </div>
        </div>

        {IS_DOCUMENTS_TABLE_VISIBLE && (
          <div className="theme-table-wrap">
            <div className={`row ${partnershipDetails?.IsDocView === 0 ? 'disable-table' : ''}`}>
              <div className="col-lg-12 col-md-12">
                <Box className="tabs-wrap border-boxs p-0" sx={{ width: '100%', marginTop: 0 }}>
                  <Box className="tab-heading tab-head-bg px-20">
                    <Tabs value={tableValue} onChange={handleTableChange} aria-label="basic tabs example">
                      <Tab label="Documents" {...a11yTableProps(0)} />
                      <Tab label="Tenant" {...a11yTableProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={tableValue} index={0}>
                    <Documents />
                  </TabPanel>
                  <TabPanel value={tableValue} index={1}>
                    <Tenant />
                  </TabPanel>
                </Box>
              </div>
            </div>
            {partnershipDetails?.IsDocView === 1 && (
              <div className="invisible">
                <Button
                  variant="contained"
                  onClick={shareNDARequestHandler}
                  disabled={partnershipDetails?.Requested === 0 || partnershipDetailsLoading}
                >
                  {partnershipDetails?.Requested === 0
                    ? 'NDA has been sent to your registered email'
                    : 'To view Partnership Performance Documents click here to send NDA'}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PartnershipAssetsDetailsComponent;
