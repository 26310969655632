import React, { useCallback, useEffect, useState } from 'react';
import { Client, Conversation, Paginator } from '@twilio/conversations';
import { RootState } from '../../../redux/store';
import { connect } from 'react-redux';
import { compareDesc } from 'date-fns';
import debounce from '../debounce/debounce';
import { ShowLoadingAction, HideLoadingAction } from '../../../redux/actions/loadingActions';
import { ShowSnackbarAction } from '../../../redux/actions/snackbarActions';
import { createGroupChat, getChatMemberInfo, getChatUserList, getLpUserList } from '../../../services/chatService';
import { UserDetails } from '../../../models/authModels';
import { SnackbarPayload } from '../../../models/snackbarModels';
import { ChatLPList, CustomConversation, UserSuggestion } from '../../../models/chatModels';
import ChatListComponent from './chatListComponent';

const ChatListContainer = ({
  userDetails,
  ShowLoadingAction,
  HideLoadingAction,
  ShowSnackbarAction,
  setScreenVal,
  client,
  setCustomConvo,
}: ChatListContainerProps) => {
  const [userSuggestions, setUserSuggestions] = useState<UserSuggestion[]>();
  const [chatHistory, setChatHistory] = useState<CustomConversation[]>();
  const [lpUserList, setLpUserList] = useState<ChatLPList[]>();
  const [value, setValue] = useState<0 | 1>(0);
  const [searchText, setSearchText] = useState('');
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [conversationObj, setConversationObj] = useState<Paginator<Conversation>>();
  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    setValue(newValue);
  };

  useEffect(() => {
    setSearchText('');
    if (!value) {
      getChatHistory();
    } else {
      getLpUserListing();
    }
  }, [client, value]);

  useEffect(() => {
    if (value === 0) {
      client?.on('conversationAdded', getChatHistory);
      client?.on('messageAdded', getChatHistory);
    }
    return () => {
      client?.off('conversationAdded', getChatHistory);
      client?.off('messageAdded', getChatHistory);
    };
  }, [value]);

  const getLpUserListing = () => {
    ShowLoadingAction();
    getLpUserList()
      .then(response => {
        HideLoadingAction();
        setLpUserList(response.data.data);
      })
      .catch(() => {
        HideLoadingAction();
        ShowSnackbarAction({
          message: 'Error Fetching LP List',
          severity: 'error',
        });
      });
  };

  const generateConversationsWithPrivateChatDetails = (
    chatItems: Conversation[],
    // TODO: get type from API
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chatMemberInfo: any,
  ) => {
    return chatItems?.map(chatItem => {
      if (chatItem.uniqueName !== null) {
        const privateChatDetails = chatMemberInfo[chatItem.uniqueName];
        if (privateChatDetails) {
          return {
            conversation: chatItem,
            privateChatDetails: privateChatDetails?.find(
              // TODO: get type from API
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (m: any) => m.userId !== userDetails.userId,
            ),
          };
        }
      }
      return {
        conversation: chatItem,
        privateChatDetails: undefined,
      };
    });
  };

  const getChatHistory = async () => {
    try {
      ShowLoadingAction();
      const response = await client?.getSubscribedConversations();
      const uniqueNames = response?.items
        .filter(item => (item.uniqueName !== null ? item : false))
        .map(item => item.uniqueName);
      if (uniqueNames && uniqueNames.length > 0 && response) {
        const chatMemberInfo = await getChatMemberInfo({
          params: { uniqueName: uniqueNames },
        });
        const conversationsWithPrivateChatDetails = generateConversationsWithPrivateChatDetails(
          response?.items,
          chatMemberInfo?.data?.data,
        );
        conversationsWithPrivateChatDetails?.sort((a, b) => {
          return compareDesc(
            a.conversation.lastMessage?.dateCreated ??
              a.conversation?.dateUpdated ??
              a.conversation?.dateCreated ??
              new Date('1/1/1970'),
            b.conversation.lastMessage?.dateCreated ??
              b.conversation?.dateUpdated ??
              b.conversation?.dateCreated ??
              new Date('1/1/1970'),
          );
        });
        setConversationObj(response);
        setHasPrevPage(response.hasPrevPage);
        setChatHistory(conversationsWithPrivateChatDetails);
        HideLoadingAction();
      } else if (response) {
        setConversationObj(response);
        setHasPrevPage(response.hasPrevPage);
        if (response.items) {
          setChatHistory(
            response.items.map(item => {
              return {
                conversation: item,
                privateChatDetails: undefined,
              };
            }),
          );
        }
        HideLoadingAction();
      }
    } catch (error) {
      ShowSnackbarAction({
        message: "Couldn't Fetch Chat History",
        severity: 'error',
      });
    }
  };

  const fetchMoreChats = async () => {
    try {
      if (hasPrevPage && conversationObj) {
        const response = await conversationObj.prevPage();
        const uniqueNames = response?.items
          .filter(item => (item.uniqueName !== null ? item : false))
          .map(item => item.uniqueName);
        if (uniqueNames && uniqueNames.length > 0 && response) {
          const chatMemberInfo = await getChatMemberInfo({
            params: { uniqueName: uniqueNames },
          });
          const conversationsWithPrivateChatDetails = generateConversationsWithPrivateChatDetails(
            response?.items,
            chatMemberInfo?.data?.data,
          );

          conversationsWithPrivateChatDetails?.sort((a, b) => {
            return compareDesc(
              a.conversation.lastMessage?.dateCreated ??
                a.conversation?.dateUpdated ??
                a.conversation?.dateCreated ??
                new Date('1/1/1970'),
              b.conversation.lastMessage?.dateCreated ??
                b.conversation?.dateUpdated ??
                b.conversation?.dateCreated ??
                new Date('1/1/1970'),
            );
          });
          setConversationObj(response);
          setHasPrevPage(response.hasPrevPage);
          setChatHistory(conversationsWithPrivateChatDetails);
          HideLoadingAction();
        } else if (response) {
          setConversationObj(response);
          setHasPrevPage(response.hasPrevPage);
          if (response.items) {
            setChatHistory(
              response.items.map(item => {
                return {
                  conversation: item,
                  privateChatDetails: undefined,
                };
              }),
            );
          }
          HideLoadingAction();
        }
      }
    } catch (error) {
      ShowSnackbarAction({
        message: "Couldn't Fetch Chat History",
        severity: 'error',
      });
    }
  };

  const getUserList = (searchVal: string) => {
    if (searchVal) {
      ShowLoadingAction();
      getChatUserList(searchVal)
        .then(response => {
          HideLoadingAction();
          setUserSuggestions(response.data.data);
        })
        .catch(() => {
          HideLoadingAction();
          ShowSnackbarAction({
            message: 'Error Fetching Suggestions',
            severity: 'error',
          });
        });
    } else {
      setUserSuggestions(undefined);
    }
  };

  const handleSearch = useCallback(debounce(getUserList, 400), []);

  useEffect(() => {
    handleSearch(searchText);
  }, [searchText]);

  const createGroup = async (userId: number) => {
    ShowLoadingAction();
    setSearchText('');
    try {
      const payload = new FormData();
      payload.append('groupName', 'groupName');
      payload.append('isPrivate', 'true');
      payload.append('memberUserId', `${userId}`);
      const response = await createGroupChat(payload);
      if (response.data.status === 200) {
        if (client) {
          const conversation = await client.getConversationBySid(response.data.data.sid);
          const chatMemberInfo = await getChatMemberInfo({
            params: { uniqueName: [conversation.uniqueName] },
          });
          const customConvo = generateConversationsWithPrivateChatDetails(
            [conversation],
            chatMemberInfo?.data?.data,
          )[0];
          setCustomConvo(customConvo);
          setScreenVal(1);
        }
      } else {
        ShowSnackbarAction({
          message: response?.data?.message,
          severity: 'error',
        });
      }
      HideLoadingAction();
    } catch (error) {
      HideLoadingAction();
      ShowSnackbarAction({
        message: 'Error While Creating Group',
        severity: 'error',
        duration: 3000,
      });
    }
  };

  return (
    <ChatListComponent
      setScreenVal={setScreenVal}
      userSuggestions={userSuggestions}
      chatHistory={chatHistory}
      createGroup={createGroup}
      searchText={searchText}
      setSearchText={setSearchText}
      fetchMoreChats={fetchMoreChats}
      hasPrevPage={hasPrevPage}
      value={value}
      handleChange={handleChange}
      setCustomConvo={setCustomConvo}
      lpUserList={lpUserList}
      userDetails={userDetails}
    />
  );
};

interface ChatListContainerProps {
  userDetails: UserDetails;
  ShowSnackbarAction: (payload: SnackbarPayload) => void;
  ShowLoadingAction: (payload?: string) => void;
  HideLoadingAction: () => void;
  setScreenVal: (screenNum: number) => void;
  client?: Client;
  setCustomConvo: (customConvo?: CustomConversation) => void;
}

const mapStateToProps = (state: RootState) => {
  return {
    userDetails: state.auth.userDetails,
  };
};

const mapDispatchToProps = {
  ShowSnackbarAction,
  ShowLoadingAction,
  HideLoadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatListContainer);
