import { useMemo, useState } from 'react';
import { renderCurrency } from '../../../../views/utils';

const FundingTypePicker = ({
  fboBalance,
  automatedPaymentsAmount,
  setIsFboFundingOpen,
  selectedCard,
  setSelectedCard,
}: FundingTypePickerProps) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleContainerClick = (key: string) => {
    if (selectedCard === key) {
      setSelectedCard('');
    } else {
      setSelectedCard(key as 'fbo' | 'ach');
    }
  };

  const fboElegible = useMemo(() => {
    return automatedPaymentsAmount < fboBalance!;
  }, [fboBalance]);

  return (
    <div className="picker-wrapper">
      <div
        className={`pick-container ${selectedCard === 'ach' && 'active-card'}`}
        onClick={() => handleContainerClick('ach')}
      >
        <div className="content-wrapper">
          <div className="label">
            Debit full amount from <br /> default account through <br /> ACH
          </div>
        </div>
        <div className="eta-badge-wrapper">
          <div className="eta-badge">3 to 5 Days</div>
        </div>
      </div>

      <div
        className={`pick-container ${selectedCard === 'fbo' && 'active-card'} ${!fboElegible && 'disabled-card'}`}
        onClick={() => (fboElegible ? handleContainerClick('fbo') : null)}
      >
        <div className="content-wrapper">
          <div className="label">
            Pay with Extrance Treasury <br /> Balance
          </div>
          <div className="fbo-content">{renderCurrency(fboBalance!)}</div>
        </div>
        <div className="eta-badge-wrapper">
          <div className="eta-badge">{fboElegible ? 'INSTANT PAYOUT' : 'INSUFICIENT FUNDS'}</div>
          {!fboElegible && (
            <div className="tooltip-info-bt" onClick={() => setOpenTooltip(!openTooltip)}>
              i
              {openTooltip && (
                <div className="custom-tooltip-box">
                  <div className="tooltip-title">Treasury Balance funding</div>
                  <div className="tooltip-content">
                    Distributing capital using this option allows the payments to be performed instantly.
                  </div>
                  <div
                    style={{
                      textDecoration: 'underline',
                      marginTop: 4,
                      color: 'white',
                      fontSize: 14,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setIsFboFundingOpen(true);
                    }}
                  >
                    Fund the ETB
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface FundingTypePickerProps {
  fboBalance: number | null | undefined;
  automatedPaymentsAmount: number;
  setIsFboFundingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCard: '' | 'fbo' | 'ach';
  setSelectedCard: React.Dispatch<React.SetStateAction<'' | 'fbo' | 'ach'>>;
}

export default FundingTypePicker;
