export const Routes = {
  home: '/',
  login: '/login',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  createNewPassword: '/create-new-password',
  dashboard: '/dashboard',
  notifications: '/notifications',
  gpPartnerships: '/gp-partnerships',
  partnershipDetails: '/partnership-details',
  resetPassword: '/reset-password/:token',
  myProfile: '/my-profile',
  activeSubscription: '/active-subscription',
  mySubscriptions: '/my-subscriptions',
  faq: '/faq',
  globalSearch: '/global-search',
  manualPaymentStatus: '/manual-payment-status/:id',
  partnershipAssetsDetails: '/partnership-assets-details',
  assetDetails: '/asset-details/:assetId',
  users: '/users',
  editUser: '/users/edit-user/:id',
  addUser1: '/users/add-user1',
  addUser: '/users/add-user',
  roles: '/roles',
  addRole: '/roles/add',
  editRole: '/roles/edit/:roleId',
  editLpRole: '/roles/editLP',
  marketplace: '/marketplace',
  partnershipMarketplace: '/marketplace/:partnershipId',
  marketplaceOpportunity: '/marketplace/opportunity/:id',
  marketplacePartnershipValuationDetails: '/marketplace/partnership-valuation-details/:id',
  marketplaceAsset: '/marketplace/asset/:assetId/',
  banking: '/banking',
  bankingTransactionDetails: '/banking/transaction-details/:id',
  bankingQuotePaymentTransactionDetails: '/banking/quote-payment-transaction-details/:id',
  releasePayment: '/release-payment',
  releasePaymentWithId: '/release-payment/:id',
  supportRequests: '/support-requests',
  supportRequestsDetails: '/support-requests/details/:id',
  makePayment: '/make-payment',
  makePaymentWithId: '/make-payment/:id',
  quotePayment: '/quote-payment',
  quotePaymentWithId: '/quote-payment/:id',
  subscriptionPayment: '/subscription-payment',
  subscriptionPaymentWithId: '/subscription-payment/:id',
  myFunds: '/my-funds',
  myFundsDetail: '/my-funds/detail/:fundId',
  documents: '/documents',
  fundDocuments: '/documents/fund/:fundId',
  documentsFolder: '/documents/folder/:folderId',
  investmentFundDocuments: '/documents/fund/:fundId/investment/:investmentId',
  investmentFundAssetDocuments: '/documents/fund/:fundId/asset/:assetId',
  investmentFundAssetFamilyDocuments:
    '/documents/fund/:fundId/investment/:investmentId/asset/:assetId/family/:familyId',
  governance: '/governance',
  governanceDetail: '/governance/detail/:fundId',
  governanceCapCallHistory: '/governance/detail/:fundId/capcall-history',
  governanceCapCallHistoryDetail: '/governance/detail/:fundId/capcall-history/:capcallId',
  governanceDistributionHistory: '/governance/detail/:fundId/distribution-history',
  governanceDistributionHistoryDetail: '/governance/detail/:fundId/distribution-history/:distributionId',
  gpBanking: '/gpbanking',
  gpBankingFund: '/gpbanking/:fundId',
  gpBankingPaymentsApproval: '/gpbanking/:fundId/payments-approval',
  gpBankingPendingPayments: '/gpbanking/:fundId/pending-payments',
  gpBankingEtbHistory: '/gpbanking/:fundId/etb-history',
  gpBankingManageBankingInfo: '/gpbanking/:fundId/manage-banking-info',
  gpBankingTransactionsHistory: '/gpbanking/:fundId/transactions-history',
  gpBankingInvestment: '/gpbanking/:fundId/investment/:investmentId',
  partnership: '/:partnershipId',
  fundAssetDetails: '/:fundName/:fundId/asset-details/:assetId',
  fundAssetKpiDetails: '/:fundName/:fundId/asset-details/:assetId/:kpiRole/:externalId',
  errorContainer: '*',
  forbidden: '/forbidden',
};
