import { useQuery } from '@tanstack/react-query';
import { getCapitalStructure } from 'src/services/fundsService';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { QueryKey } from 'src/consts/query';

export const useCapitalStructureWidget = (fundID: number) => {
  const { showErrorSnackbar } = useSnackbar();

  const fetchCapitalStructure = async (fundID: number) => {
    try {
      const response = await getCapitalStructure(fundID);
      return response.data;
    } catch (error) {
      showErrorSnackbar((error as AxiosError).message);
    }
  };

  return useQuery({
    queryKey: [QueryKey.CapitalStructureWidget, fundID],
    queryFn: () => fetchCapitalStructure(fundID),
    select: data => {
      return data
        ? [
            {
              id: 'gp-capital',
              label: 'GP Capital',
              value: parseFloat(data.gpEquity),
            },
            {
              id: 'lp-equity',
              label: 'LP’s (equity)',
              value: parseFloat(data.lpEquity),
            },
            {
              id: 'debt',
              label: 'Debt',
              value: parseFloat(data.debt),
            },
          ]
        : [];
    },
  });
};
