import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'src/context/ShackbarContext';
import { AxiosError } from 'axios';
import { createFundDocument } from 'src/services/documentsService';

export const useUploadFundDocument = (...callback: Array<() => void>) => {
  const queryClient = useQueryClient();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ fundId, form }: { fundId: number; form: FormData }) => createFundDocument(fundId, form),
    onSuccess: responce => {
      showSuccessSnackbar(`Document ${responce.data.data.Title} has been successfully uploaded`);
      queryClient.invalidateQueries({ queryKey: ['fundDocuments'] });
      callback.forEach(cb => cb());
    },
    onError: e => {
      console.log('useUploadFundDocument error', e);
      showErrorSnackbar((e as AxiosError).message);
    },
  });
};
