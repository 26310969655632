import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { StateData, StateOptions } from 'src/models/frontendUserModels';
import IconArrowLeft from 'src/uiLibrary/icons/IconArrowLeft';
import { useUserDetails } from 'src/hooks/react-query/user/useUserDetails';
import { useRoleList } from 'src/hooks/react-query/user/userRoleList';
import { useCountryList } from 'src/hooks/react-query/partnership/useCountryList';
import { Loader } from 'src/components/common/Loader';
import { UserForm } from './components/UserForm';

export const CreateUserComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: userDetails, isLoading: isUserDetaillsLoading } = useUserDetails(id);
  const { data: roleList, isLoading: isRolesLoading } = useRoleList();
  const { data: countryList, isLoading: isCountryListLoading } = useCountryList('1');

  const states: StateOptions[] = countryList?.map((state: StateData) => ({
    label: state.Name,
    value: state.id.toString(),
  }));

  const handleBackButtonClick = () => {
    navigate('/users');
  };

  const isLoading = (id && isUserDetaillsLoading) || isRolesLoading || isCountryListLoading;

  if (isLoading) return <Loader />;

  return (
    <Grid container justifyContent="flex-start" alignItems="stretch" spacing={2}>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <Box className="back-fund-wrap ml" onClick={handleBackButtonClick}>
          <IconButton size="large" component="span">
            <IconArrowLeft stroke="#8F8F8F" />
          </IconButton>
          <Typography className="back-text">Back</Typography>
        </Box>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <Typography gutterBottom component="p" className="titlePage">
          {userDetails ? 'Edit' : 'Add'} user {userDetails && `${userDetails.FirstName} ${userDetails.LastName}`}
        </Typography>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={12}>
        <UserForm states={states} roleList={roleList} userDetails={userDetails} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
};
