import { ReactComponent as ChevronDown } from '../../../../assets/images/icons/chevron-down.svg';
import { renderCurrency } from '../../../../views/utils';

const PaymentMethodAccordion = ({
  fboBalance,
  selectedCard,
  manualAmount,
  automatedAmount,
  unrolled,
  setUnrolled,
}: PaymentMethodAccordionProps) => {
  const handleChevronClick = () => {
    if (unrolled) {
      setUnrolled('');
    } else {
      setUnrolled('payment-method');
    }
  };
  return (
    <div className={`review-accordion-container ${unrolled && 'unrolled-accordion'}`}>
      <div className="accordion-header">
        <div className="title-and-subtitle-wrap">
          <p className="title">Payment method</p>
          <p className="subtitle">Details about how the distribution is going to be funded</p>
        </div>
        <div className="chevron-box" onClick={() => handleChevronClick()}>
          <ChevronDown />
        </div>
      </div>
      <div className="accordion-content">
        <div style={{ width: 800 }}>
          <div className="payment-method-container">
            <div className="content-row">
              <div className="payment-total-label">Total issued in automated payments</div>
              <div className="payment-total-content">{renderCurrency(automatedAmount)}</div>
            </div>
            <div className="content-row">
              <div className="funding-origin-item">
                {selectedCard === 'ach' ? (
                  <>
                    <div className="label">Funded via ACH by</div>
                    <div className="funding-info-chip-body">
                      <div className="item">
                        <div className="label">Account alias</div>
                        <div className="content">Bell Enclave Funding</div>
                      </div>
                      <div className="v-separator" />
                      <div className="item">
                        <div className="label">Routing</div>
                        <div className="content">904848192</div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="label">Funded by Extrance Treasury Balance</div>
                    <div className="funding-info-chip-body">
                      <div className="item">
                        <div className="label">ETB Available</div>
                        <div className="content">{renderCurrency(fboBalance!)}</div>
                      </div>
                      <div className="v-separator" />
                      <div className="item">
                        <div className="label">ETB Available after payment</div>
                        <div className="content">{renderCurrency(fboBalance! - automatedAmount)}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="eta-item">
                <div className="label">Estimated time of arrival</div>
                <div className={`funding-info-chip-body ${selectedCard === 'ach' ? 'ach-type' : 'fbo-type'}`}>
                  {selectedCard === 'ach' ? '3 to 5 days' : 'INSTANT PAYOUT'}
                </div>
              </div>
            </div>
            <div className="h-separator" />
            <div className="content-row">
              <div className="payment-total-label">Total issued in manual payments</div>
              <div className="payment-total-content">{renderCurrency(manualAmount)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PaymentMethodAccordionProps {
  fboBalance: number | undefined | null;
  selectedCard: '' | 'fbo' | 'ach';
  manualAmount: number;
  automatedAmount: number;
  unrolled: boolean;
  setUnrolled: React.Dispatch<React.SetStateAction<string>>;
}

export default PaymentMethodAccordion;
