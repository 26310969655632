import { AxiosResponse } from 'axios';
import ServiceClient from './serviceClientConfig';
import { validateError } from 'src/utils/validateError';
import { RoleItem, RoleListMetaData } from 'src/models/rolesModels';
import { RoleDTO } from 'src/views/add-role/validations';
import { RoleById } from 'src/types/Roles';

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const addRole = (payload: RoleDTO) => {
  return serviceClient.post('roles', payload);
};

export const editRole = (payload: RoleDTO, roleId: string) => {
  return serviceClient.put('roles/' + roleId, payload);
};

export const getRoleList = async (
  pageNumber: number,
  sortField: 'name' | 'created',
  sortOrder: 'desc' | 'asc',
  limit: number,
  filterStatus?: 0 | 1,
  searchString?: string,
) => {
  let url = `company/1/roles?page=${pageNumber}&order_field=${sortField}&order_sort=${sortOrder.toUpperCase()}&page_size=${limit}`; //`company/1/roles?page=${pageNumber}&order_field=${sortField}&order_sort=${sortOrder.toUpperCase()}&limit=${limit}`;

  if (filterStatus === 0 || filterStatus === 1) {
    url = url + `&filter_status=${filterStatus}`;
  }

  if (searchString) {
    url = url + `&search_string=${searchString}`;
  }

  try {
    const response = await serviceClient.get(url);

    return response as AxiosResponse<{
      data: RoleItem[];
      meta: RoleListMetaData;
    }>;
  } catch (error) {
    console.error('getRoleList error: ', error);
    throw await validateError(error);
  }
};

export const getRoleDetailsById = async (roleId: string) => {
  try {
    const url = `roles/${roleId}`;
    const response = await serviceClient.get(url);
    return response.data as AxiosResponse<RoleById>;
  } catch (error) {
    console.error('getRoleDetailsById error: ', error);
    throw await validateError(error);
  }
};

export const getPrivilegeListForRole = async () => {
  try {
    const url = 'privileges/privilege-list?isFront=1';
    const response = await serviceClient.get(url);
    //TODO: add type from codegen
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return response.data as AxiosResponse<any>;
  } catch (error) {
    console.error('getPrivilegeListForRole error: ', error);
    throw await validateError(error);
  }
};

export const getRolePrivilege = (userId: number | undefined) => {
  return serviceClient.get(`users/user/role-privilege?userId=${userId}`);
};

export const deleteRole = (roleId: number) => {
  const url = `roles/${roleId}`;
  return serviceClient.delete(url);
};

export const updateRoleStatus = (roleId: number, isActive: number) => {
  const url = `roles/update-status/${roleId}`;
  return serviceClient.put(url, { IsActive: isActive });
};
